import NavBar from "../components/NavBar/NavBar";
import RMSideBar from "../components/SideBar/RMSideBar";
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import Pagination from "../components/Common/Pagination/Pagination";
import { useParams } from "react-router-dom";
import { NotificationsContainer } from "@rio-cloud/rio-uikit";
import MessageTable from "../components/Message/Table/MessageTable";
import MessageDetailSideBar from "../components/Message/SideBar/MessageDetailsSideBar";
import { get } from "../api/apiService";
import { PaginationStore } from "../stores/pagination";
import { useEffect, useState } from "react";
import { FilterStore } from "../stores/filter";
import { observer } from "mobx-react-lite";
import config from "../config";
import mockMessageList from "../assets/MockData/Messages";
import Notification from "@rio-cloud/rio-uikit/Notification";
import { messageTableSettings } from "../helpers/constants";

const paginationStore = new PaginationStore();
const filterStore = new FilterStore();

export default observer(() => {
  const { id: messageId } = useParams();
  const { itemsLength, limit, offset, currentPage } = paginationStore;
  const { query } = filterStore;

  const [isLoading, setIsLoading] = useState(true);
  const [messagesList, setMessagesList] = useState([]);

  useEffect(() => {
    if (config.AUTH_disabled) {
      setMessagesList(mockMessageList);
      paginationStore.setItemsLength(200);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      get(`/v1/messages/?offset=${offset}&limit=${limit}&s=${query}`)
        .then((data) => {
          setMessagesList(data.results);

          paginationStore.setItemsLength(data.totalCount);
          setIsLoading(false);
        })
        .catch((error) => {
          Notification.error(`Failed to fetch: ${error}`, "Error", 100000);
        });
    }
  }, [offset, limit, currentPage, query, messageId]);
  useEffect(() => {
    // When unmounting the page:
    return () => {
      paginationStore.resetToDefaults();
      filterStore.resetToDefaults();
    };
  }, []);

  return (
    <ApplicationLayout className="MyServiceLayout bg-lighter">
      <ApplicationLayout.Header>
        <NavBar />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar>
        <RMSideBar />
      </ApplicationLayout.Sidebar>
      {messageId && (
        <ApplicationLayout.Sidebar className="right">
          <MessageDetailSideBar messageId={messageId} />
        </ApplicationLayout.Sidebar>
      )}
      <ApplicationLayout.Body>
        <NotificationsContainer />
        <MessageTable
          selectedRow={messageId}
          isLoading={isLoading}
          messagesList={messagesList}
          paginationStore={paginationStore}
          filterStore={filterStore}
          messageTableSettings={messageTableSettings}
        />
        <Pagination size={itemsLength} paginationStore={paginationStore} />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
});
