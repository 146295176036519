import Sidebar from "@rio-cloud/rio-uikit/Sidebar";

export function DetailsSideBar({ children, ...props }) {
  const { title, onClose } = props;
  return (
    <Sidebar
      fly
      resizable
      title={title}
      titleClassName="padding-left-10"
      position={Sidebar.RIGHT}
      width={850}
      minWidth={850}
      maxWidth={1200}
      enableFullscreenToggle={true}
      onClose={onClose}
    >
      <div className="padding-left-20 padding-right-20 margin-top-10 margin-bottom-10">
        {children}
      </div>
    </Sidebar>
  );
}
