import StatisticsState from "./StatisticsState";
import StatisticsType from "./StatisticsType";

export default function StatisticsDetails(props) {
  const { messages, isLoading } = props;
  return (
    <div className="display-grid gap-20 grid-cols-2">
      <StatisticsState messages={messages} isLoading={isLoading} />
      <StatisticsType messages={messages} isLoading={isLoading} />
    </div>
  );
}
