import ContentLoader from "@rio-cloud/rio-uikit/ContentLoader";
import Collapse from "@rio-cloud/rio-uikit/Collapse";
import { useState } from "react";

export default function MessageDetailsMessage(props) {
  const { isLoading, messageDetails } = props;

  const [showCollapse, setShowCollapse] = useState(false);

  return (
    <div data-testid="message-details-message-section">
      <h5
        className="messageDetailHeading"
        onClick={() => setShowCollapse((state) => !state)}
      >
        <span className="rioglyph rioglyph-document text-size-20 margin-right-10"></span>
        <span className="margin-right-5">Message</span>

        <span
          className={
            showCollapse
              ? "rioglyph rioglyph-chevron-down"
              : "rioglyph rioglyph-chevron-right"
          }
        ></span>
      </h5>
      <div className="breakline margin-bottom-20" />

      <Collapse in={showCollapse}>
        <div>
          <div className="margin-bottom-10">
            <label className="display-block">Title</label>
            {isLoading ? (
              <ContentLoader />
            ) : (
              <span className="text-primary">{messageDetails?.title}</span>
            )}
          </div>
          <div className="margin-bottom-10">
            <label className="display-block">Content</label>
            {isLoading ? (
              <ContentLoader />
            ) : (
              <span className="text-primary">{messageDetails?.content}</span>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
}
