import ProgressBarLabels from "./ProgressBarLabels";
import ContentLoader from "@rio-cloud/rio-uikit/ContentLoader";

export default function MessageDetailsProgress(props) {
  const { isLoading, messageDetails } = props;

  return (
    <>
      <h5>
        <span className="rioglyph rioglyph-route-view text-size-20 margin-right-10"></span>
        Progress
      </h5>
      <div className="breakline margin-bottom-20" />
      {isLoading ? (
        <ContentLoader />
      ) : (
        <ProgressBarLabels status={messageDetails?.status} />
      )}
    </>
  );
}
