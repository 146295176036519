import ButtonDropdown from "@rio-cloud/rio-uikit/ButtonDropdown";
import { observer } from "mobx-react-lite";

const TimelineLength = observer((props) => {
  const { timelineStore } = props;

  const handleFilterChange = (value) => {
    timelineStore.setFilter(value);
  };

  const options = [7, 14, 30, 100, 300];

  const buttonOptions = options.map((option) => {
    return {
      value: `last ${option} days`,
      onSelect: () => handleFilterChange(option),
    };
  });

  return (
    <div className="display-flex justify-content-end margin-bottom-20">
      <ButtonDropdown
        title={`last ${timelineStore.getFilter} days`}
        items={buttonOptions}
      />
    </div>
  );
});

export default TimelineLength;
