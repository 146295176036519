export const breakpointsDefault = {
  small: 150,
  smallLength: 15,
  medium: 210,
  mediumLength: 20,
  large: 260,
  largeLength: 27,
};

export const breakpointsDevices = {
  small: 180,
  smallLength: 8,
  medium: 250,
  mediumLength: 17,
  large: 330,
  largeLength: 20,
};

export const breakpointsTypes = {
  small: 150,
  smallLength: 17,
  medium: 150,
  mediumLength: 17,
  large: 160,
  largeLength: 20,
};

export const breakpointsTitles = {
  small: 140,
  smallLength: 15,
  medium: 170,
  mediumLength: 20,
  large: 200,
  largeLength: 27,
};

export const breakpointsActivity = {
  small: 150,
  smallLength: 15,
  medium: 210,
  mediumLength: 18,
  large: 260,
  largeLength: 27,
};

export const breakPointsVehicleName = {
  small: 150,
  smallLength: 8,
  medium: 210,
  mediumLength: 15,
  large: 260,
  largeLength: 20,
};
