import names from "../../../../../assets/MockData/names.json";
import { Tooltip, OverlayTrigger } from "@rio-cloud/rio-uikit";

const TableCellDriverRenderer = (props) => {
  const { dataField, isDeviceSimulatedAsOffline } = props;

  const randomNumber = Math.floor(Math.random() * names.length) - 1;

  return (
    <td data-field={dataField}>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        overlay={
          <Tooltip>
            The driver names are not real data but a simulation of a future
            feature.
          </Tooltip>
        }
      >
        <span>
          {isDeviceSimulatedAsOffline ? (
            <>
              <span class="rioglyph rioglyph-question-sign margin-right-5"></span>
              <span>No driver reported</span>
            </>
          ) : (
            <>
              <span class="rioglyph rioglyph-driver margin-right-5"> </span>
              <span>{names[randomNumber]}</span>
            </>
          )}
        </span>
      </OverlayTrigger>
    </td>
  );
};

export default TableCellDriverRenderer;
