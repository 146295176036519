import { TableSearch, TableToolbar } from "@rio-cloud/rio-uikit";
import { useState } from "react";
import TableSettingsDialog from "@rio-cloud/rio-uikit/TableSettingsDialog";
import { useEffect } from "react";
import FilterButton from "../Buttons/FilterButton";
import Filter from "../Filter/Filter";
import { useStores } from "../../../stores/context";
import CreateMessage from "../../Message/Dialog/CreateMessage";
import config from "../../../config";
import WhitelistButton from "../../Message/Dialog/Buttons/WhitelistButton";

export default function RMTableToolbar(props) {
  const [showTableSettingsDialog, setShowTableSettingsDialog] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const { authStore } = useStores();

  const {
    searchValue,
    setSearchValue,
    toolbarOptions,
    setColumnOrder,
    defaultColumnOrder,
    columnOrder,
    hiddenColumns,
    columnLabels,
    disabledColumns,
    columnsDetails,
    setHiddenColumns,
    setColumnsDetails,
    filterStore,
    paginationStore,
  } = props;

  const { searchBar, filter, whiteListing, createMessageButton } =
    toolbarOptions;
  const [whitelistButtonActive, setWhitelistButtonActive] =
    useState(whiteListing);
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchValue]);

  useEffect(() => {
    if (whitelistButtonActive) {
      filterStore.setQuery("Testrack RemoteMessage");
      paginationStore.setCurrentPage(1);
    } else {
      filterStore.setQuery(debouncedSearchValue);
      paginationStore.setCurrentPage(1);
    }
  }, [
    debouncedSearchValue,
    filterStore,
    paginationStore,
    whitelistButtonActive,
  ]);

  const handleToggleTableSettingsDialog = () =>
    setShowTableSettingsDialog(!showTableSettingsDialog);

  const handleColumnChange = (
    newColumnOrder,
    newHiddenColumns,
    newColumnsDetails = columnsDetails
  ) => {
    setColumnOrder(newColumnOrder);
    setHiddenColumns(newHiddenColumns);
    setColumnsDetails(newColumnsDetails);
  };

  const handleColumnDetailsChange = (column, newColumnDetails) => {
    const updatedColumnsDetails = { ...columnsDetails };
    updatedColumnsDetails[column] = newColumnDetails;
    setColumnsDetails(updatedColumnsDetails);
  };

  const handleSearchValueChange = (newSearchValue) => {
    setSearchValue(newSearchValue);
  };

  const handleShowFilter = (showFilter) => {
    setShowFilterDialog(showFilter);
  };

  const handleClickWhiteListButton = () => {
    setWhitelistButtonActive((whitelistButtonActive) => !whitelistButtonActive);
    setSearchValue("");
  };

  return (
    <>
      <Filter
        show={showFilterDialog}
        filterStore={filterStore}
        onHide={() => handleShowFilter(false)}
      />

      <TableToolbar>
        <div data-testid="table-toolbar" className="table-toolbar-container">
          <div className="table-toolbar-group-left display-flex align-items-center">
            {searchBar && (
              <div className="table-toolbar-column">
                <TableSearch
                  value={searchValue}
                  onChange={handleSearchValueChange}
                  placeholder="Search table ..."
                />
              </div>
            )}
            {filter && (
              <div className="table-toolbar-column">
                <FilterButton
                  className={"margin-right-10"}
                  onShowFilter={() => handleShowFilter(true)}
                />
              </div>
            )}
            {whiteListing && authStore.hasRole("Admin") && (
              <WhitelistButton
                whitelistButtonActive={whitelistButtonActive}
                handleClickWhiteListButton={handleClickWhiteListButton}
              />
            )}
          </div>
          <div className="table-toolbar-group-right">
            {createMessageButton &&
              (authStore.hasRole("User") ||
                authStore.hasRole("Admin") ||
                config.AUTH_disabled) && (
                <div className="table-toolbar-column">
                  <CreateMessage />
                </div>
              )}

            <div className="table-toolbar-column">
              <button
                data-testid="table-settings-btn"
                className="btn btn-default btn-icon-only"
                onClick={handleToggleTableSettingsDialog}
                id={"tableSettingsBtn"}
              >
                <span className="rioglyph rioglyph-settings" />
              </button>
            </div>

            {showTableSettingsDialog && (
              <TableSettingsDialog
                show={showTableSettingsDialog}
                title="Table Settings"
                onHide={handleToggleTableSettingsDialog}
                onColumnChange={handleColumnChange}
                defaultColumnOrder={defaultColumnOrder}
                defaultHiddenColumns={[]}
                columnOrder={columnOrder}
                hiddenColumns={hiddenColumns}
                columnLabels={columnLabels}
                disabledColumns={disabledColumns}
                closeButtonText="Close"
                resetButtonText="Reset to default"
                searchPlaceholder="Search by column name"
                notFoundMessage="No column found for this search value"
                columnsDetails={columnsDetails}
                autoLabel="Auto"
                onColumnDetailsChange={handleColumnDetailsChange}
                immediateChange
              />
            )}
          </div>
        </div>
      </TableToolbar>
    </>
  );
}
