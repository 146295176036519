import { useEffect, useState } from "react";
import { get } from "../../../api/apiService";
import MessageTable from "../../Message/Table/MessageTable";
import { PaginationStore } from "../../../stores/pagination";
import { FilterStore } from "../../../stores/filter";
import Pagination from "../../Common/Pagination/Pagination";
import { observer } from "mobx-react-lite";
import config from "../../../config";
import mockMessageList from "../../../assets/MockData/Messages";
import ContentLoader from "@rio-cloud/rio-uikit/ContentLoader";
import Notification from "@rio-cloud/rio-uikit/Notification";
import { vehicleDetailsMessageTable } from "../../../helpers/constants";

const paginationStore = new PaginationStore("messageInVehicleDetailsTable");
const filterStore = new FilterStore();

export default observer((props) => {
  const { vehicleId } = props;
  const { itemsLength, limit, offset, currentPage } = paginationStore;
  const { query } = filterStore;

  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (config.AUTH_disabled) {
      setMessages(mockMessageList);
      paginationStore.setItemsLength(200);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      get(`/v1/messages?offset=${offset}&limit=${limit}&s=${vehicleId}`)
        .then((data) => {
          setMessages(data.results);
          paginationStore.setItemsLength(data.totalCount);
          setIsLoading(false);
        })
        .catch((error) => {
          Notification.error(`Failed to fetch: ${error}`, "Error", 100000);
        });
    }
  }, [vehicleId, offset, limit, currentPage, query]);
  useEffect(() => {
    // When unmounting the page:
    return () => {
      paginationStore.resetToDefaults();
      filterStore.resetToDefaults();
    };
  }, []);

  return (
    <>
      <h5>
        <span class="rioglyph rioglyph-envelope text-size-20 margin-right-10"></span>
        Messages
      </h5>
      <div className="breakline margin-bottom-20" />
      <div data-testid="vehicle-details-messages-section">
        <div>
          {isLoading ? (
            <ContentLoader />
          ) : (
            <>
              {vehicleId &&
                !isLoading &&
                (itemsLength > 0 ? (
                  <>
                    <MessageTable
                      selectedRow={vehicleId}
                      isLoading={isLoading}
                      messagesList={messages}
                      paginationStore={paginationStore}
                      filterStore={filterStore}
                      messageTableSettings={vehicleDetailsMessageTable}
                    />
                    <Pagination
                      paginationStore={paginationStore}
                      size={itemsLength}
                    />
                  </>
                ) : (
                  <span className="text-primary center">
                    No messages found for this vehicle.
                  </span>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
});
