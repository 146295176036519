import React from "react";
import { useNavigate } from "react-router-dom";
import ForbiddenState from "@rio-cloud/rio-uikit/ForbiddenState";

const Unauthorized = () => {
  let navigate = useNavigate();
  return (
    <ForbiddenState
      headline="Sorry, access not allowed"
      message={"Please contact the RemoteMessage Team."}
      buttons={[
        {
          text: "Get access",
          onClick: () => navigate("/support"),
        },
      ]}
    />
  );
};

export default Unauthorized;
