import { observable, action, computed, makeObservable } from "mobx";
import { isNumber } from "lodash";

let instance; // singleton instance

export class TimelineStore {
  filterValue = 100;

  get getFilter() {
    return this.filterValue;
  }

  constructor() {
    makeObservable(this, {
      filterValue: observable,
      getFilter: computed,
      setFilter: action,
    });
  }

  setFilter(filter) {
    const castToNumber = Number(filter);
    if (isNumber(castToNumber) && !isNaN(castToNumber)) {
      this.filterValue = castToNumber;
    }
  }

  static instance() {
    if (!instance) {
      instance = new TimelineStore();
    }
    return instance;
  }
}
