import { post } from "../../../../api/apiService";

const SendMessageBtn = (props) => {
  const { authStore, messageDetails, setUpdatedStatus, setErrorNotification } =
    props;

  const sendMessage = () => {
    const messageUpdate = {
      ...messageDetails,
      status: "In_transfer",
      timeStampStatus: new Date(),
      timeStampInTransfer: new Date(),
      sentBy: authStore.user?.profile?.email,
    };
    post(`/v1/messages`, messageUpdate)
      .then(() => {
        setUpdatedStatus("in transfer");
      })
      .catch((error) => {
        setErrorNotification(`Failed to update Message: ${error}`);
      });
  };

  const showSendMessageBtn = () => {
    if (
      authStore.hasRole("Admin") &&
      !messageDetails?.timestampSend &&
      messageDetails?.approved &&
      !(messageDetails?.status === "In_transfer") &&
      !(messageDetails?.status === "Failed")
    ) {
      return true;
    }
    if (
      authStore.hasRole("User") &&
      !messageDetails?.timestampSend &&
      messageDetails?.approved &&
      !(messageDetails?.status === "In_transfer") &&
      !(messageDetails?.status === "Failed") &&
      (messageDetails.createdBy === authStore.user.profile.email ||
        messageDetails.approvedBy === authStore.user.profile.email)
    ) {
      return true;
    }

    if (authStore.hasRole("Guest")) {
      return false;
    } else return false;
  };

  return showSendMessageBtn() ? (
    <button
      data-testid="message-detail-send-btn"
      type="button"
      className="btn btn-primary margin-bottom-10"
      onClick={sendMessage}
    >
      <span className="rioglyph  rioglyph-send"></span>
      Send Message
    </button>
  ) : null;
};

export default SendMessageBtn;
