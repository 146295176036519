import React, { useEffect, useState } from "react";
import { Tooltip, OverlayTrigger } from "@rio-cloud/rio-uikit";

const TableCellShortener = (props) => {
  const { cellData, parentCellSize, breakpoints } = props;
  const [displayCellData, setDisplayCellData] = useState("No Data");
  const [isShortened, setIsShortened] = useState(false);

  const { small, smallLength, medium, mediumLength, large, largeLength } =
    breakpoints;

  const calculateDisplayData = () => {
    if (!cellData) {
      return;
    }

    if (parentCellSize < small) {
      handleShortening(smallLength);
    } else if (parentCellSize < medium) {
      handleShortening(mediumLength);
    } else if (parentCellSize < large) {
      handleShortening(largeLength);
    } else {
      setDisplayCellData(cellData);
      setIsShortened(false);
    }
  };

  const handleShortening = (minLength) => {
    if (cellData.length > minLength) {
      const truncatedId = cellData.slice(0, minLength - 5) + "(...)";
      setDisplayCellData(truncatedId);
      setIsShortened(true);
    } else {
      setDisplayCellData(cellData);
      setIsShortened(false);
    }
  };

  useEffect(() => {
    calculateDisplayData();
    // eslint-disable-next-line
  }, [cellData, parentCellSize]);

  return isShortened ? (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      overlay={<Tooltip>{cellData}</Tooltip>}
    >
      <span>{displayCellData}</span>
    </OverlayTrigger>
  ) : (
    <span>{cellData}</span>
  );
};

export default TableCellShortener;
