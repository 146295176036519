import ContentLoader from "@rio-cloud/rio-uikit/ContentLoader";
import { useNavigate } from "react-router-dom";
import ClipboardIcon from "../../Common/ClipboardIcon/ClipboardIcon";

export default function MessageDetailsTarget(props) {
  const { messageDetails, vehicleDetails, isLoadingVehicle } = props;

  const navigate = useNavigate();

  const handleNavigateVehicle = () => {
    navigate(`/vehicles/${messageDetails?.vehicleId}`);
  };
  return (
    <>
      <h5>
        <span className="rioglyph rioglyph-truck-baseline text-size-20 margin-right-10"></span>
        Target Information
      </h5>
      <div className="breakline margin-bottom-20" />
      <div data-testid="message-details-target-section" className="row">
        <div className="col-md-6">
          <div className="margin-bottom-10">
            <label className="display-block">Vehicle Name</label>

            {isLoadingVehicle ? (
              <ContentLoader />
            ) : (
              <span className="text-primary">{vehicleDetails?.name}</span>
            )}
          </div>
        </div>{" "}
        <div className="col-md-6">
          <div className="margin-bottom-10 margin-top-5">
            {isLoadingVehicle ? (
              <ContentLoader />
            ) : (
              <button
                type="button"
                class="btn btn-default"
                onClick={handleNavigateVehicle}
              >
                <span class="rioglyph rioglyph-truck" aria-hidden="true"></span>
                View Vehicle
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="margin-bottom-10">
            <label className="display-block">Vehicle ID</label>
            {isLoadingVehicle || !vehicleDetails?.id ? (
              <ContentLoader />
            ) : (
              <>
                <span className="text-primary">{vehicleDetails?.id}</span>
                <ClipboardIcon textToCopy={vehicleDetails?.id} />
              </>
            )}
          </div>
        </div>{" "}
      </div>
    </>
  );
}
