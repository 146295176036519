import Tablebody from "./Tablebody";
import TableHead from "./Tablehead";
import TableToolbar from "./TableToolBar";
import SortDirection from "@rio-cloud/rio-uikit/SortDirection";
import React, { useState } from "react";
import TableColumnGroup from "./TableColumnGroup";

export default function Table(props) {
  const {
    defaultColumnOrder,
    columnLabels,
    data = [],
    toolbarOptions,
    defaultColumnDetails,
    disabledColumns,
    isLoading,
    selectedRow,
    toolbarElement,
    filterStore,
    paginationStore,
    defaultHiddenColums,
    ...rest
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState("vehicleId");
  const [sortDir, setSortDir] = useState(SortDirection.ASCENDING);
  const [columnOrder, setColumnOrder] = useState(defaultColumnOrder);
  const [hiddenColumns, setHiddenColumns] = useState(defaultHiddenColums || []);
  const [columnsDetails, setColumnsDetails] = useState(defaultColumnDetails);

  const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));

  const getSortDir = (sortDir, sortBy, previousSortBy) => {
    if (sortBy === previousSortBy) {
      return sortDir === SortDirection.ASCENDING
        ? SortDirection.DESCENDING
        : SortDirection.ASCENDING;
    }
    return SortDirection.ASCENDING;
  };

  return (
    <>
      {toolbarOptions.tableToolbar && (
        <TableToolbar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          toolbarOptions={toolbarOptions}
          defaultColumnOrder={defaultColumnOrder}
          columnOrder={columnOrder}
          hiddenColumns={hiddenColumns}
          columnLabels={columnLabels}
          disabledColumns={disabledColumns}
          columnsDetails={columnsDetails}
          setColumnOrder={setColumnOrder}
          setHiddenColumns={setHiddenColumns}
          setColumnsDetails={setColumnsDetails}
          toolbarElement={toolbarElement}
          filterStore={filterStore}
          paginationStore={paginationStore}
        />
      )}

      <table
        key="table"
        className={
          "table table-layout-fixed table-bordered table-sticky table-head-filled table-hover"
        }
      >
        <colgroup>
          {columns.map((column) => {
            return (
              <TableColumnGroup
                key={column}
                column={column}
                columnsDetails={columnsDetails}
              />
            );
          })}
        </colgroup>
        <thead>
          <tr>
            {columns.map((column) => {
              return (
                <TableHead
                  key={column}
                  column={column}
                  columnLabels={columnLabels[column]}
                  setSortBy={setSortBy}
                  setSortDir={setSortDir}
                  sortDir={sortDir}
                  sortBy={sortBy}
                  getSortDir={getSortDir}
                />
              );
            })}
          </tr>
        </thead>
        <Tablebody
          columnOrder={columns}
          columnLabels={columnLabels}
          sortDir={sortDir}
          sortBy={sortBy}
          vehicleList={data}
          hiddenColumns={hiddenColumns}
          isLoading={isLoading}
          selectedRow={selectedRow}
          {...rest}
        />
      </table>
    </>
  );
}
