import TimeLineGraph from "./TimelineGraph";

const TimelineMessagesSent = (props) => {
  const { messages, isLoading, timelineStore } = props;

  const sortByProperty = "timestampSend";

  const title = "Messages Sent";

  return (
    <TimeLineGraph
      messages={messages}
      timelineStore={timelineStore}
      isLoading={isLoading}
      sortByProperty={sortByProperty}
      title={title}
    />
  );
};

export default TimelineMessagesSent;
