import { ButtonDropdown } from "@rio-cloud/rio-uikit";
import { Tooltip, OverlayTrigger, Popover } from "@rio-cloud/rio-uikit";

export const typeOptions = [
  "Technical Information",
  "Service Information",
  "Recall",
];
export const severityOptions = ["Low", "Medium", "High"];

export default function MessageForm(props) {
  const {
    selectedVehicle,
    selectedType,
    selectedSeverity,
    titleInputValue,
    contentInputValue,
    handleTitleInputChange,
    handleContentInputChange,
    showToolTipType,
    showToolTipTitle,
    showToolTipContent,
    handleTypeInputChange,
    showToolTipSeverity,
    handleSeverityInputChange,
    handleKBAInputChange,
    KBAInputValue,
    showKBAToolTip,
  } = props;

  const severityOptionValues = severityOptions.map((option) => ({
    value: option,
    onSelect: () => handleSeverityInputChange(option),
  }));

  const typeOptionValues = typeOptions.map((option) => ({
    value: option,
    onSelect: () => handleTypeInputChange(option),
  }));

  return (
    <div className="display-flex justify-content-center align-items-center margin-top-50">
      <div className="display-grid gap-20">
        <div className="display-grid gap-20 grid-cols-1">
          <div>
            <span className="text-primary text-size-16 margin-right-15">
              Target:{" "}
            </span>
            <span className="text-primary">
              {selectedVehicle?.vin} / {selectedVehicle?.name}{" "}
            </span>
          </div>
        </div>
        <div className="display-grid gap-10 grid-cols-2">
          <div className={"message-creation-form-button"}>
            <span className="text-primary text-size-16 margin-right-15">
              Type:
            </span>
            <OverlayTrigger
              show={showToolTipType}
              overlay={<Tooltip id="tooltip">Select Type</Tooltip>}
            >
              <ButtonDropdown
                id="message-selection-select-type-dropdown"
                title={selectedType || ""}
                items={typeOptionValues}
                data-testid="message-creation-type-selection"
              />
            </OverlayTrigger>
          </div>

          <div className={"message-creation-form-button"}>
            <span className="text-primary text-size-16 margin-right-15">
              Severity:
            </span>
            <OverlayTrigger
              show={showToolTipSeverity}
              overlay={<Tooltip id="tooltip">Select Severity</Tooltip>}
            >
              <ButtonDropdown
                className={"selectSeverity"}
                title={selectedSeverity || ""}
                items={severityOptionValues}
                data-testid="message-creation-severity-selection"
              />
            </OverlayTrigger>{" "}
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={
                <Popover
                  id="popover-message-creation"
                  titleClassName={"text-size-h5"}
                  className={"popover-message-creation"}
                  title="Which Severity should you pick?"
                >
                  <span>
                    <strong>Severity Low:</strong> <br /> A minor problem that
                    affects something but doesn't have a serious impact on
                    users.
                    <br /> <br />
                    <strong>Severity Medium:</strong> <br /> A Problem that
                    causes errors, minor problems for users, or a heavy system
                    load. <br /> <br />
                    <strong>Severity High:</strong> <br /> A critical problem
                    that affects a large number of users in production or has a
                    significant security impact.
                  </span>
                </Popover>
              }
            >
              <span className="rioglyph rioglyph-info-sign text-size-h4"></span>
            </OverlayTrigger>
          </div>
        </div>

        {selectedType === "Recall" && (
          <>
            <div className="display-grid gap-10 grid-cols-1">
              <div className={"message-creation-form-button"}>
                <span className="text-primary text-size-16 margin-right-15">
                  KBA-Reference-Number:
                </span>
                <div>
                  <OverlayTrigger
                    show={showKBAToolTip}
                    overlay={
                      <Tooltip id="tooltip">
                        Enter a valid KBA-Reference-Number
                      </Tooltip>
                    }
                  >
                    <input
                      data-testid="message-creation-kba-input"
                      value={KBAInputValue}
                      onChange={handleKBAInputChange}
                      className="padding-5 margin-top-5"
                      type="text"
                    />
                  </OverlayTrigger>
                </div>{" "}
              </div>
            </div>
          </>
        )}

        <div className="display-grid gap-10 grid-cols-1">
          <div>
            <span
              htmlFor="titleInput"
              className="text-primary text-size-16 margin-right-15 margin-bottom-5"
            >
              Title:
            </span>
            <OverlayTrigger
              show={showToolTipTitle}
              overlay={<Tooltip id="tooltip">Enter Title</Tooltip>}
            >
              <input
                data-testid="message-creation-title-input"
                className="padding-5 margin-top-5"
                name="titleInput"
                key="titleInput"
                id={"titleInput"}
                value={titleInputValue}
                onChange={handleTitleInputChange}
                style={{ width: "100%" }}
              ></input>
            </OverlayTrigger>
          </div>
        </div>

        <div>
          <span className="text-primary text-size-16">Content:</span>
          <OverlayTrigger
            show={showToolTipContent}
            overlay={<Tooltip id="tooltip">Enter Content</Tooltip>}
          >
            <textarea
              data-testid="message-creation-content-input"
              className="padding-5 margin-top-5 form-control"
              name="contentInput"
              key="contentInput"
              value={contentInputValue}
              rows="5"
              id="message-creation-textarea"
              maxLength="2000"
              onChange={handleContentInputChange}
            ></textarea>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
}
