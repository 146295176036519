import NavBar from "../components/NavBar/NavBar";
import RMSideBar from "../components/SideBar/RMSideBar";
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import { NotificationsContainer } from "@rio-cloud/rio-uikit";
import { get } from "../api/apiService";
import { useEffect, useState } from "react";
import TimelineMessages from "../components/Timeline/TimelineMessages";
import { TimelineStore } from "../stores/timeline";
import Notification from "@rio-cloud/rio-uikit/Notification";

export default function TimelinePage() {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const timelineStore = new TimelineStore();

  useEffect(() => {
    setIsLoading(true);
    get("/v1/messages")
      .then((data) => {
        setMessages(data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        Notification.error(`Failed to fetch: ${error}`, "Error", 100000);
      });
  }, []);

  return (
    <ApplicationLayout className="MyServiceLayout bg-lighter">
      <ApplicationLayout.Header>
        <NavBar />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar>
        <RMSideBar />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body>
        <NotificationsContainer />
        <TimelineMessages
          timelineStore={timelineStore}
          messages={messages}
          isLoading={isLoading}
        />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
}
