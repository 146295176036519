export const ServiceRoles = {
  User: "User",
  Guest: "Guest",
  Admin: "Admin",
};

export const vehicleTableSettings = {
  defaultColumnOrder: [
    "id",
    "name",
    "vin",
    "devices",
    "driver",
    "status",
    "activityState",
  ],
  // uncomment following line to render table without driver column
  // defaultColumnOrder: ["id", "name", "vin", "devices", "status"],

  columnLabels: {
    id: "Vehicle ID",
    name: "Vehicle Name",
    vin: "VIN",
    devices: "Device S/N",
    status: "Status",
    driver: "Reported Driver",
    activityState: "Activity State",
  },
  disabledColumns: [],
  hiddenColums: ["status"],
  toolbarOptions: {
    searchBar: true,
    filter: true,
    tableToolbar: true,
    createMessageButton: true,
  },
  columnDetails: {
    serialnumber: {
      width: 0,
      defaultWidth: 300,
      maxWidth: 300,
      minWidth: 100,
    },
    name: {
      width: 0,
      defaultWidth: 220,
      maxWidth: 250,
      minWidth: 100,
    },
    vin: {
      width: 0,
      defaultWidth: 220,
      maxWidth: 220,
      minWidth: 100,
    },
    status: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 350,
      minWidth: 100,
    },
    driver: {
      width: 0,
      defaultWidth: 220,
      maxWidth: 250,
      minWidth: 100,
    },
    id: {
      width: 0,
      defaultWidth: 400,
      maxWidth: 400,
      minWidth: 100,
    },
  },
};

export const targetSelectionSettings = {
  defaultColumnOrder: [
    "id",
    "name",
    "vin",
    "devices",
    "driver",
    "status",
    "activityState",
  ],
  // uncomment following line to render table without driver column
  // defaultColumnOrder: ["id", "name", "vin", "devices", "status"],

  columnLabels: {
    id: "Vehicle ID",
    name: "Vehicle Name",
    vin: "VIN",
    devices: "Device S/N",
    status: "Status",
    driver: "Reported Driver",
    activityState: "Activity State",
  },
  disabledColumns: [],
  hiddenColums: ["status"],
  toolbarOptions: {
    searchBar: true,
    filter: true,
    tableToolbar: true,
    whiteListing: true,
  },
  columnDetails: {
    serialnumber: {
      width: 0,
      defaultWidth: 300,
      maxWidth: 300,
      minWidth: 100,
    },
    name: {
      width: 0,
      defaultWidth: 220,
      maxWidth: 350,
      minWidth: 100,
    },
    vin: {
      width: 0,
      defaultWidth: 220,
      maxWidth: 350,
      minWidth: 100,
    },
    status: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 350,
      minWidth: 100,
    },
    driver: {
      width: 0,
      defaultWidth: 220,
      maxWidth: 350,
      minWidth: 100,
    },
    id: {
      width: 0,
      defaultWidth: 400,
      maxWidth: 400,
      minWidth: 100,
    },
  },
};

export const messageTableSettings = {
  defaultColumnOrder: [
    "title",
    "id",
    "severity",
    "type",
    "status",
    "timeStampStatus",
  ],
  columnLabels: {
    title: "Title",
    id: "Message ID",
    severity: "Severity",
    type: "Message Type",
    status: "Status",
    timeStampStatus: "TimeStamp",
  },
  disabledColumns: [],
  toolbarOptions: {
    searchBar: true,
    filter: true,
    tableToolbar: true,
    createMessageButton: true,
  },
  columnDetails: {
    title: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 400,
    },
    id: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 400,
      minWidth: 100,
    },
    severity: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 200,
    },
    type: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 200,
    },
    status: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 0,
    },
    timeStampStatus: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 200,
    },
  },
};

export const vehicleDetailsMessageTable = {
  defaultColumnOrder: [
    "title",
    "id",
    "severity",
    "type",
    "status",
    "timeStampStatus",
  ],
  columnLabels: {
    title: "Title",
    id: "Message ID",
    severity: "Severity",
    type: "Message Type",
    status: "Status",
    timeStampStatus: "TimeStamp",
  },
  disabledColumns: [],
  toolbarOptions: {
    searchBar: false,
    filter: false,
    tableToolbar: false,
    createMessageButton: false,
  },
  columnDetails: {
    title: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 400,
      minWidth: 100,
    },
    id: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 400,
      minWidth: 100,
    },
    severity: {
      width: 0,
      defaultWidth: 60,
      maxWidth: 350,
      minWidth: 60,
    },
    type: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 350,
      minWidth: 100,
    },
    status: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 350,
      minWidth: 100,
    },
    timeStampStatus: {
      width: 0,
      defaultWidth: 0,
      maxWidth: 350,
      minWidth: 100,
    },
  },
};

export const INIT_URL_LINK = "initUrlLink";
