export default function ConfirmMessage(props) {
  const {
    selectedType,
    selectedVehicle,
    selectedSeverity,
    titleInputValue,
    contentInputValue,
    KBAInputValue,
    showLoadingAnimation,
  } = props;
  return (
    <>
      <div
        data-testid="message-creation-message-overview"
        className="padding-left-20 padding-top-20 padding-bottom-20 padding-right-20 border rounded-large margin-right-50 margin-left-50"
      >
        <h5 className="margin-bottom-10 margin-top-0">General</h5>
        <div data-testid="message-creation-general-section" className="row">
          <div className="col-md-6">
            <div className="margin-bottom-10">
              <label className="display-block">Message Type</label>
              <span className="text-primary">{selectedType}</span>
            </div>
          </div>
          <div className="margin-bottom-10 col-md-6">
            <label className="display-block">Severity</label>
            <span className="text-primary">{selectedSeverity}</span>
          </div>
          {KBAInputValue && (
            <div className="col-lg-12 margin-bottom-10">
              <label className="display-block">KBA-Recall-Reference:</label>
              <span className="text-primary">{KBAInputValue}</span>
            </div>
          )}
        </div>
        <h5 className="margin-bottom-10 margin-top-20">Target Information</h5>
        <div data-testid="message-creation-target-section" className="row">
          <div className="col-md-6">
            <div className="margin-bottom-10">
              <label className="display-block">Vehicle Name</label>

              <span className="text-primary">{selectedVehicle?.name}</span>
            </div>
          </div>
          <div className="margin-bottom-10 col-md-6">
            <label className="display-block">Vehicle ID</label>
            <span className="text-primary">{selectedVehicle?.id}</span>
          </div>
        </div>
        <h5 className="margin-bottom-10 margin-top-20">Message</h5>
        <label className="display-block">Title:</label>
        <div className="margin-bottom-10">{titleInputValue}</div>
        <label className="display-block">Content:</label>
        <div>{contentInputValue}</div>
      </div>
      {showLoadingAnimation && (
        <div className="display-flex justify-content-center align-items-center margin-top-20">
          <div className="display-flex align-items-center gap-15">
            <h5>Creating message...</h5>
          </div>
          <span className="rioglyph rioglyph-tire spinning text-size-h1"></span>
        </div>
      )}
    </>
  );
}
