import { DetailsSideBar } from "../../Common/DetailsSideBar/DetailsSideBar";
import VehicleDetails from "./VehicleDetails";
import { useNavigate } from "react-router-dom";
import { get } from "../../../api/apiService";
import { useEffect, useState } from "react";
import config from "../../../config";

export default function VehicleDetailSideBar(props) {
  const [vehicleDetails, setVehicleDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { vehicleId } = props;

  const navigate = useNavigate();

  useEffect(() => {
    if (config.AUTH_disabled) {
      setVehicleDetails(null);
      setIsLoading(false);
    } else {
      get(`/v1/devices/${vehicleId}`).then((data) => {
        setIsLoading(true);
        setVehicleDetails(data);
        setIsLoading(false);
      });
    }
  }, [vehicleId]);
  const onClose = () => {
    navigate(`/vehicles`);
  };
  return (
    <DetailsSideBar
      title={
        <h4>
          <span class="rioglyph rioglyph-detail-view-info text-size-30 margin-right-10"></span>
          Vehicle Details
        </h4>
      }
      vehicleId={vehicleId}
      onClose={onClose}
      vehicleDetails={vehicleDetails}
    >
      <VehicleDetails
        isLoading={isLoading}
        vehicleId={vehicleId}
        vehicleDetails={vehicleDetails}
      />
    </DetailsSideBar>
  );
}
