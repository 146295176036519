import TableLength from "./TableLength";
import Pages from "./Pages";
import { observer } from "mobx-react-lite";
import { PaginationStore } from "../../../stores/pagination";

const Pagination = observer((props) => {
  const { paginationStore = new PaginationStore(), size } = props;

  const siblingCount = 3;
  const currentPage = paginationStore?.currentPage;
  const pageSize = paginationStore?.size;

  const handlePaginationChange = (page) => {
    paginationStore.setCurrentPage(page);
  };

  const handleLengthChange = (value) => {
    paginationStore.setLimit(value);
    paginationStore.setCurrentPage(1);
  };

  return (
    <div className="display-flex margin-top-10 margin-bottom-10">
      <TableLength
        pageSize={pageSize}
        setPageSize={handleLengthChange}
        setCurrentPage={handlePaginationChange}
      />
      <Pages
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={size}
        pageSize={pageSize}
        siblingCount={siblingCount}
        onPageChange={handlePaginationChange}
      />
    </div>
  );
});

export default Pagination;
