import { isNumber } from "lodash";
import { observable, action, computed, makeObservable } from "mobx";

let instance; // singleton instance

export const defaultValues = {
  defaultTable: {
    limit: 50,
    currentPage: 1,
    itemsLength: 0,
  },
  messageInVehicleDetailsTable: {
    limit: 10,
    currentPage: 1,
    itemsLength: 0,
  },
  targetSelectionTable: {
    limit: 10,
    currentPage: 1,
    itemsLength: 0,
  },
};

export class PaginationStore {
  defaultValues;
  limit = defaultValues.limit;
  currentPage = defaultValues.currentPage;
  itemsLength = defaultValues.itemsLength;

  get size() {
    return this.limit;
  }
  get offset() {
    if (this.currentPage && this.limit) {
      return (this.currentPage - 1) * this.limit;
    } else {
      return 0;
    }
  }

  /**
   *
   * @param {DefaultValues} params
   */
  constructor(tableKey) {
    const params = defaultValues[tableKey] || defaultValues.defaultTable;

    makeObservable(this, {
      limit: observable,
      currentPage: observable,
      itemsLength: observable,

      offset: computed,
      size: computed,

      setLimit: action,
      setCurrentPage: action,
      setItemsLength: action,

      resetToDefaults: action,
    });

    this.defaultValues = params;
    Object.assign(this, params);
  }
  resetToDefaults() {
    Object.assign(this, this.defaultValues);
  }

  setLimit(limit) {
    if (isNumber(limit)) {
      this.limit = limit;
    } else {
      const castToNumber = Number(limit);
      if (isNumber(castToNumber) && !isNaN(castToNumber)) {
        this.limit = castToNumber;
      }
    }
  }
  setCurrentPage(currentPage) {
    if (isNumber(currentPage)) {
      this.currentPage = currentPage;
    } else {
      const castToNumber = Number(currentPage);
      if (isNumber(castToNumber) && !isNaN(castToNumber)) {
        this.currentPage = castToNumber;
      }
    }
  }
  setItemsLength(itemsLength) {
    if (isNumber(itemsLength)) {
      this.itemsLength = itemsLength;
    } else {
      const castToNumber = Number(itemsLength);
      if (isNumber(castToNumber) && !isNaN(castToNumber)) {
        this.itemsLength = castToNumber;
      }
    }
  }

  /**
   *
   * @returns {PaginationStore} Returns a singleton instance of PaginationStore
   */
  static instance() {
    if (!instance) {
      instance = new PaginationStore();
    }
    return instance;
  }
}
