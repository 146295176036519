import ContentLoader from "@rio-cloud/rio-uikit/ContentLoader";
import ClipboardIcon from "../../Common/ClipboardIcon/ClipboardIcon";
import TableCellDriverRenderer from "../../Common/Table/helpers/TableRenderers/TableCellDriverRenderer";
import VehicleDetailActivityState from "./helper/VehicleDetailActivityState";

export default function VehicleDetailsGeneral(props) {
  const { isLoading, vehicleDetails } = props;

  const rng = Math.random() > 0.75;

  return (
    <>
      <h5>
        <span className="rioglyph rioglyph-truck-baseline text-size-20 margin-right-10"></span>
        General
      </h5>
      <div
        data-testid="vehicle-details-general-section"
        className="breakline margin-bottom-20"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="margin-bottom-10">
            <label className="display-block">Vehicle Name</label>

            {isLoading || !vehicleDetails?.name ? (
              <ContentLoader />
            ) : (
              <span className="text-primary">{vehicleDetails?.name}</span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="margin-bottom-10">
            <label className="display-block">VIN</label>
            {isLoading || !vehicleDetails?.vin ? (
              <ContentLoader />
            ) : (
              <>
                <span className="text-primary">{vehicleDetails?.vin}</span>
                <ClipboardIcon textToCopy={vehicleDetails?.vin} />
              </>
            )}
          </div>
        </div>
        <div className="margin-bottom-10">
          <label className="display-block">Vehicle ID</label>

          {isLoading || !vehicleDetails?.id ? (
            <ContentLoader />
          ) : (
            <>
              <span className="text-primary">{vehicleDetails?.id}</span>
              <ClipboardIcon textToCopy={vehicleDetails?.id} />
            </>
          )}
        </div>
        <div className="col-md-6">
          <div className="margin-bottom-10">
            <label className="display-block">Driver</label>
            {isLoading || !vehicleDetails?.vin ? (
              <ContentLoader />
            ) : (
              <>
                <TableCellDriverRenderer isDeviceSimulatedAsOffline={rng} />
              </>
            )}
          </div>
        </div>
        <div className="margin-bottom-10">
          <label className="display-block">Activity</label>

          {isLoading || !vehicleDetails?.id ? (
            <ContentLoader />
          ) : (
            <VehicleDetailActivityState
              isDeviceSimulatedAsOffline={rng}
              row={vehicleDetails}
            />
          )}
        </div>
      </div>
    </>
  );
}
