import React from "react";

function FilterButton(props) {
  const {
    onShowFilter = () => {
      /* */
    },
    id = null,
  } = props;
  return (
    <div className="margin-left-10">
      <button
        type="button"
        className="btn btn-default "
        onClick={onShowFilter}
        id={id}
      >
        <span className="rioglyph rioglyph-filter"></span>
        {"Filter"}
      </button>
    </div>
  );
}
export default FilterButton;
