export const BASEURL = `${window.location.protocol}//${window.location.host}`;

const config = {
  BASEURL,
  AUTH_ISSUER:
    "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_4LXoUTI8W",
  AUTH_CLIENT_ID: "460145et85rp9c0sma6sm4hfq7",
  AUTH_disabled: false,
};

config.load = () => {
  if (process.env.REACT_APP_ENV_AUTH === "1") {
    config.AUTH_disabled = true;
  }
};

export default config;
