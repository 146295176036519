import { Tooltip, OverlayTrigger } from "@rio-cloud/rio-uikit";
import TableCellShortener from "./TableCellShortener";
import { breakpointsActivity } from "./constants/constants";
import useResizableElementWidth from "./hooks/useResizableElementWidth";
import { useRef } from "react";

const TableCellActivityStateRenderer = (props) => {
  const { isDeviceSimulatedAsOffline, row } = props;

  const elRef = useRef();

  const width = useResizableElementWidth(elRef, props);

  const labelRenderer = {
    offline: "text-color-gray",
  };

  const generateTimeStamp = () => {
    const randomMinute = Math.floor(Math.random() * 60);

    if (randomMinute <= 3) {
      return "a moment ago.";
    } else return `${randomMinute} minutes ago.`;
  };

  const boxStyling =
    "border text-medium text-center margin-auto padding-right-15 padding-top-5 padding-left-15 width-100 padding-bottom-5 border-width-2 rounded-large";

  const generateLabel = () => {
    if (row?.name === "Testrack RemoteMessage") {
      return {
        text: "Online",
        style: "border-color-success text-color-success",
      };
    }
    if (Math.random() > 0.5) {
      return {
        text: "Online",
        style: "border-color-success text-color-success",
      };
    } else
      return {
        text: `Last seen ${generateTimeStamp()}`,
        style: "border-color-warning text-color-warning",
      };
  };

  const label = generateLabel();

  return (
    <td>
      <div ref={elRef}>
        <OverlayTrigger
          placement={"bottom"}
          trigger={["hover", "focus"]}
          overlay={
            <Tooltip>
              This activity state is not real data but a simulation of a future
              feature.
            </Tooltip>
          }
        >
          <span>
            {isDeviceSimulatedAsOffline &&
            row?.name !== "Testrack RemoteMessage" ? (
              <>
                <span className={`${boxStyling} ${labelRenderer["offline"]}`}>
                  Offline
                </span>
              </>
            ) : (
              <>
                <span className={`${boxStyling} ${label.style}`}>
                  <TableCellShortener
                    cellData={label.text}
                    breakpoints={breakpointsActivity}
                    parentCellSize={width}
                  />
                </span>
              </>
            )}
          </span>
        </OverlayTrigger>
      </div>
    </td>
  );
};

export default TableCellActivityStateRenderer;
