import TableCellShortener from "./TableCellShortener";
import { useRef } from "react";
import useResizableElementWidth from "./hooks/useResizableElementWidth";
import { breakpointsDefault } from "./constants/constants";
import ClipboardIcon from "../../../ClipboardIcon/ClipboardIcon";

export default function TableCellIdRenderer(props) {
  const { id, dataField } = props;
  const elRef = useRef();

  const width = useResizableElementWidth(elRef, props);

  return (
    <td data-field={dataField}>
      <div ref={elRef}>
        <TableCellShortener
          cellData={id}
          parentCellSize={width}
          breakpoints={breakpointsDefault}
        />
        <ClipboardIcon textToCopy={id} />
      </div>
    </td>
  );
}
