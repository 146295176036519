import TableCellShortener from "./TableCellShortener";
import useResizableElementWidth from "./hooks/useResizableElementWidth";
import { useRef } from "react";
import { breakPointsVehicleName } from "./constants/constants";

const TableCellVehicleNameRenderer = ({ columnItem, dataField, row }) => {
  const elRef = useRef();

  const width = useResizableElementWidth(elRef);

  const boxStyling =
    "border text-medium text-center margin-auto padding-right-15 padding-top-5 padding-left-15 width-100 padding-bottom-5 border-width-2 rounded-large margin-right-10";

  const labelClasses = {
    PRODUCTION: {
      style: "border-color-danger text-color-danger",
      text: "PROD",
    },
    TESTING: {
      style: "border-color-success text-color-success",
      text: "TEST",
    },
  };

  return (
    <td>
      <div ref={elRef}>
        <span className={`${boxStyling} ${labelClasses[row.labels].style}`}>
          <span>{labelClasses[row.labels].text}</span>
        </span>
        <TableCellShortener
          cellData={columnItem}
          parentCellSize={width}
          breakpoints={breakPointsVehicleName}
        />
      </div>
    </td>
  );
};

export default TableCellVehicleNameRenderer;
