export default function FullPageLoader() {
  return (
    <div className="display-flex justify-content-center align-items-center margin-bottom-auto margin-top-auto height-100vh">
      <div className="display-flex align-items-center gap-15">
        <h5>Loading...</h5>
        <span className="rioglyph rioglyph-tire spinning text-size-h1"></span>
      </div>
    </div>
  );
}
