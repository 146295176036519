import ContentLoader from "@rio-cloud/rio-uikit/ContentLoader";
import ClipboardIcon from "../../Common/ClipboardIcon/ClipboardIcon";
import SeverityLabelRenderer from "../../Common/Table/helpers/TableRenderers/SeverityLabelRenderer";

export default function MessageDetailsGeneral(props) {
  const { messageId, isLoading, messageDetails } = props;

  return (
    <>
      <h5>
        <span className="rioglyph rioglyph-envelope text-size-20 margin-right-10"></span>
        General
      </h5>
      <div className="breakline margin-bottom-20" />
      <div data-testid="message-details-general-section" className="row">
        <div className="col-lg-12">
          <div className="margin-bottom-10">
            <label className="display-block">Message ID</label>

            {isLoading ? (
              <ContentLoader />
            ) : (
              <>
                <span className="text-primary">{messageId}</span>
                <ClipboardIcon textToCopy={messageId} />
              </>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="margin-bottom-10">
            <label className="display-block">Message Type</label>
            {isLoading ? (
              <ContentLoader />
            ) : (
              <span className="text-primary">{messageDetails?.type}</span>
            )}
          </div>
        </div>
        <div className="margin-bottom-10">
          <label className="display-block">Severity</label>
          {isLoading ? (
            <ContentLoader />
          ) : (
            <span
              className={`border text-medium text-center margin-auto padding-right-15 padding-left-15 width-100 padding-bottom-2 border-width-2 rounded-large ${SeverityLabelRenderer(
                messageDetails?.severity
              )}`}
            >
              {messageDetails?.severity}
            </span>
          )}
        </div>
        {!isLoading && messageDetails?.kbaNumber && (
          <div className="col-lg-12">
            <div className="margin-bottom-10">
              <label className="display-block">KBA Number</label>

              {isLoading ? (
                <ContentLoader />
              ) : (
                <span className="text-primary">
                  {messageDetails?.kbaNumber}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
