import { Tooltip, OverlayTrigger } from "@rio-cloud/rio-uikit";

const VehicleDetailActivityState = (props) => {
  const { isDeviceSimulatedAsOffline, row } = props;

  const labelRenderer = {
    offline: "text-color-gray",
  };

  const generateTimeStamp = () => {
    const randomMinute = Math.floor(Math.random() * 60);

    if (randomMinute <= 3) {
      return "a moment ago.";
    } else return `${randomMinute} minutes ago.`;
  };

  const boxStyling =
    "border text-medium text-center margin-auto padding-right-10 padding-top-1 padding-left-10 width-100 padding-bottom-1 border-width-2 rounded-large";

  const generateLabel = () => {
    if (row?.name === "Testrack RemoteMessage") {
      return {
        text: "Online",
        style: "border-color-success text-color-success",
      };
    }
    if (Math.random() > 0.5) {
      return {
        text: "Online",
        style: "border-color-success text-color-success",
      };
    } else
      return {
        text: `Last seen ${generateTimeStamp()}`,
        style: "border-color-warning text-color-warning",
      };
  };

  const label = generateLabel();

  return (
    <td>
      <div>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          overlay={
            <Tooltip>
              This activity state is not real data but a simulation of a future
              feature.
            </Tooltip>
          }
        >
          <span>
            {isDeviceSimulatedAsOffline &&
            row?.name !== "Testrack RemoteMessage" ? (
              <>
                <span className={`${boxStyling} ${labelRenderer["offline"]}`}>
                  Offline
                </span>
              </>
            ) : (
              <span className={`${boxStyling} ${label.style}`}>
                {label.text}
              </span>
            )}
          </span>
        </OverlayTrigger>
      </div>
    </td>
  );
};

export default VehicleDetailActivityState;
