import VehicleTable from "../../../Vehicle/Table/VehicleTable";
import { useState, useEffect } from "react";
import { get } from "../../../../api/apiService";
import { PaginationStore } from "../../../../stores/pagination";
import { FilterStore } from "../../../../stores/filter";
import { observer } from "mobx-react-lite";
import config from "../../../../config";
import mockVehicleList from "../../../../assets/MockData/Vehicles";
import Pagination from "../../../Common/Pagination/Pagination";
import { targetSelectionSettings } from "../../../../helpers/constants";

const paginationStore = new PaginationStore("targetSelectionTable");
const filterStore = new FilterStore("targetSelectionFilter");

export default observer((props) => {
  const { setSelectedVehicle, setSelectedRow, vehicleId } = props;

  const { itemsLength, limit, offset, currentPage } = paginationStore;
  const { query, filters } = filterStore;

  const [isLoading, setIsLoading] = useState(true);
  const [vehicleList, setVehicleList] = useState([]);

  const createFilterString = () => {
    if (!filters || filters.length === 0) {
      return "";
    }

    const baseString = "&";
    const string = filters.join(baseString);

    return string;
  };

  useEffect(() => {
    if (config.AUTH_disabled) {
      setVehicleList(mockVehicleList);
      setIsLoading(false);
    } else {
      setIsLoading(true);

      get(
        `/v1/devices/?offset=${offset}&limit=${limit}&s=${query}&${createFilterString()}`
      ).then((data) => {
        setVehicleList(data.results);

        paginationStore.setItemsLength(data.totalCount);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [offset, limit, currentPage, query, filters]);

  useEffect(() => {
    // When unmounting the page:
    return () => {
      paginationStore.resetToDefaults();
      filterStore.resetToDefaults();
    };
  }, []);

  const rowClicked = (row) => {
    setSelectedVehicle(row);
    setSelectedRow(row.id);
  };

  return (
    <>
      <VehicleTable
        rowClicked={rowClicked}
        selectedRow={vehicleId}
        isLoading={isLoading}
        vehicleList={vehicleList}
        itemsLength={itemsLength}
        filterStore={filterStore}
        paginationStore={paginationStore}
        vehicleTableSettings={targetSelectionSettings}
      />
      <Pagination size={itemsLength} paginationStore={paginationStore} />
    </>
  );
});
