import { observable, action, makeObservable } from "mobx";

let instance; // singleton instance

export const Services = {
  vehicles: "/vehicles",
  vehicleDetails: "/vehicles/:id",
  messages: "/messages",
  messageDetails: "/messages/:id",
  statistics: "/statistics",
};

export class ServiceStore {
  currentService = Services.RemoteMessage;

  constructor() {
    makeObservable(this, {
      currentService: observable,
      setCurrentService: action,
    });
  }

  setCurrentService(current) {
    this.currentService = current;
  }

  static instance() {
    if (!instance) {
      instance = new ServiceStore();
    }
    return instance;
  }
}
