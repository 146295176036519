import React from "react";
import {
  ApplicationLayout,
  NotificationsContainer,
} from "@rio-cloud/rio-uikit";
import NavBar from "../components/NavBar/NavBar";
import Unauthorized from "../components/Auth/Unauthorized";
import RMSideBar from "../components/SideBar/RMSideBar";

export default function UnauthorizedPage() {
  return (
    <ApplicationLayout className="MyServiceLayout bg-lighter">
      <ApplicationLayout.Header>
        <NavBar />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar>
        <RMSideBar showNavElements={["Support"]} />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body className="auth">
        <NotificationsContainer />
        <Unauthorized />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
}
