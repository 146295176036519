export default function convertDateFormat(dateStr) {
  try {
    const dateObj = new Date(dateStr);
    const formattedDate = `${dateObj.getDate()}.${
      dateObj.getMonth() + 1
    }.${dateObj.getFullYear()} ${dateObj.getHours()}:${String(
      dateObj.getMinutes()
    ).padStart(2, "0")}`;
    return formattedDate;
  } catch (error) {
    return "Invalid date format";
  }
}
