import MessageDetailsGeneral from "./MessageDetailsGeneral";
import MessageDetailsMessage from "./MessageDetailsMessage";
import { useStores } from "../../../stores/context";
import { MessageDetailsEvents } from "./MessageDetailsEvents";
import MessageDetailsTarget from "./MessageDetailsTarget";
import MessageDetailsProgress from "./MessageDetailsProgress";
import { useState } from "react";
import ClearableInput from "@rio-cloud/rio-uikit/ClearableInput";
import SendMessageBtn from "./Buttons/SendMessageBtn";
import UpdateMessageBtn from "./Buttons/UpdateMessageBtn";
import DeclineMessageBtn from "./Buttons/DeclineMessageBtn";
import ApproveMessageBtn from "./Buttons/ApproveMessageBtn";
import CancelMessageDeclineBtn from "./Buttons/CancelMessageDeclineBtn";
import DeclineMessageConfirmBtn from "./Buttons/DeclineMessageConfirmBtn";

export default function MessageDetails(props) {
  const {
    messageId,
    isLoading,
    messageDetails,
    isLoadingVehicle,
    vehicleDetails,
    setUpdatedStatus,
    loadMessages,
    updateButtonSpinning,
    setErrorNotification,
  } = props;

  const [showDeclineMessageInput, setShowDeclineMessageInput] = useState(false);
  const [messageDeclineText, setMessageDeclineText] = useState("");

  const { authStore } = useStores();

  const updateStatus = () => {
    loadMessages();
  };

  const handleCancleDecline = () => {
    setShowDeclineMessageInput((state) => !state);
    setMessageDeclineText("");
  };

  const handleMessageDeclineText = (e) => {
    setMessageDeclineText(e);
  };

  return (
    <div className={"message-details"}>
      <div className="display-flex align-items-center">
        <div className="margin-right-auto">
          {!showDeclineMessageInput && !isLoading && (
            <ApproveMessageBtn
              authStore={authStore}
              messageDetails={messageDetails}
              setUpdatedStatus={setUpdatedStatus}
            />
          )}
          {!showDeclineMessageInput && !isLoading && (
            <DeclineMessageBtn
              handleCancleDecline={handleCancleDecline}
              authStore={authStore}
              messageDetails={messageDetails}
            />
          )}

          {!showDeclineMessageInput && !isLoading && (
            <SendMessageBtn
              setUpdatedStatus={setUpdatedStatus}
              authStore={authStore}
              messageDetails={messageDetails}
              setErrorNotification={setErrorNotification}
            />
          )}
        </div>
        {!showDeclineMessageInput && !isLoading && (
          <UpdateMessageBtn
            updateDateStatus={updateStatus}
            updateButtonSpinning={updateButtonSpinning}
            authStore={authStore}
            messageDetails={messageDetails}
          />
        )}
        {showDeclineMessageInput && (
          <div className="width-100pct" style={{ width: "100%" }}>
            <ClearableInput
              placeholder={"Enter reason for message refusal."}
              value={messageDeclineText}
              onChange={(e) => handleMessageDeclineText(e)}
              className="width-100pct margin-bottom-5"
            />
            <DeclineMessageConfirmBtn
              setUpdatedStatus={setUpdatedStatus}
              messageDeclineText={messageDeclineText}
              messageDetails={messageDetails}
              authStore={authStore}
              setShowDeclineMessageInput={setShowDeclineMessageInput}
            />
            <CancelMessageDeclineBtn
              handleCancleDecline={handleCancleDecline}
            />
          </div>
        )}
      </div>
      <MessageDetailsGeneral
        messageId={messageId}
        isLoading={isLoading}
        messageDetails={messageDetails}
      />

      <MessageDetailsTarget
        isLoading={isLoading}
        messageDetails={messageDetails}
        isLoadingVehicle={isLoadingVehicle}
        vehicleDetails={vehicleDetails}
      />
      <MessageDetailsProgress
        isLoading={isLoading}
        messageDetails={messageDetails}
      />
      <MessageDetailsMessage
        isLoading={isLoading}
        messageDetails={messageDetails}
      />
      <MessageDetailsEvents
        isLoading={isLoading}
        messageDetails={messageDetails}
      />
    </div>
  );
}
