export default function TableColumnGroup(props) {
  const { columnsDetails, column } = props;

  const style =
    columnsDetails[column] && columnsDetails[column].width
      ? {
          minWidth: columnsDetails[column].width,
          width: columnsDetails[column].width,
        }
      : {};

  return <col key={column} style={style} />;
}
