import React from 'react';
import { toJS } from 'mobx';
import { AuthStore } from './auth';

export const StoreContext = React.createContext();

export const init = () => {
    const authStore = AuthStore.instance();
    
    const stores = {
        authStore,
    };

    // expose the stores to the global object to aid debugging
    global.stores = stores;
    global.toJS = toJS;
    return stores;
};

export const useStores = () => {
    const store = React.useContext(StoreContext);
    if (!store) {
        throw new Error('useStore must be used within a StoreProvider.');
    }

    return store;
};

export const StoreProvider = ({ children }) => {
    const store = init();
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
