import Notification from "@rio-cloud/rio-uikit/Notification";

const ClipboardIcon = (props) => {
  const { textToCopy } = props;

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
    Notification.success(`"${text}" copied to Clipboard.`);
  };

  return (
    <span
      className="rioglyph rioglyph-duplicate margin-left-5 cursor-pointer"
      onClick={(e) => {
        copyToClipBoard(textToCopy);
        e.stopPropagation();
      }}
    ></span>
  );
};

export default ClipboardIcon;
