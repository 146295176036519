import NavBar from "../components/NavBar/NavBar";
import RMSideBar from "../components/SideBar/RMSideBar";
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import { NotificationsContainer } from "@rio-cloud/rio-uikit";
import StatisticsDetails from "../components/Statistics/StatisticsDetail";
import StatisticsOverview from "../components/Statistics/StatisticsOverview";
import { useEffect, useState } from "react";
import { get } from "../api/apiService";
import Notification from "@rio-cloud/rio-uikit/Notification";

export default function StatisticsPage() {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    get(`/v1/messages`)
      .then((data) => {
        setMessages(data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        Notification.error(`Failed to fetch: ${error}`, "Error", 100000);
      });
  }, []);
  return (
    <ApplicationLayout className="MyServiceLayout bg-lighter">
      <ApplicationLayout.Header>
        <NavBar />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar>
        <RMSideBar />
      </ApplicationLayout.Sidebar>

      <ApplicationLayout.Body>
        <StatisticsOverview messages={messages} isLoading={isLoading} />
        <StatisticsDetails messages={messages} isLoading={isLoading} />
        <NotificationsContainer />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
}
