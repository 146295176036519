import Dialog from "@rio-cloud/rio-uikit/lib/es/Dialog";
import FilterBody from "./FilterBody";

const Filter = (props) => {
  const { show, onHide, filterStore } = props;
  return (
    <Dialog
      show={show}
      title={"Adjust filters"}
      bodyClassName={"padding-top-10"}
      body={<FilterBody filterStore={filterStore} />}
      onHide={onHide}
      className="filterDialog"
      showCloseButton
      bsSize="sm"
    />
  );
};

export default Filter;
