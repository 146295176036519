import { useEffect, useState } from "react";
import AnimatedNumber from "@rio-cloud/rio-uikit/AnimatedNumber";

export default function StatisticsOverview(props) {
  const { messages, isLoading } = props;
  const [numberMessagesCreated, setNumberMessagesCreated] = useState();
  const [numberMessagesSent, setNumberMessagesSent] = useState();

  const countMessagesSent = () => {
    const messagesSent = messages?.filter((message) => {
      return message.status === "Sent" || message.status === "Delievered";
    });
    return messagesSent?.length;
  };

  useEffect(() => {
    setNumberMessagesCreated(messages?.length);
    setNumberMessagesSent(countMessagesSent());
    // eslint-disable-next-line
  }, [messages]);
  return (
    <div className="border margin-bottom-20 shadow-hard bg-lightest">
      <h2 className="margin-top-20 text-center margin-bottom-25 text-size-h1 padding-top-10">
        Messages
      </h2>
      <div className="display-flex flex-row justify-content-around text-size-h3 margin-top-20 margin-bottom-25 padding-bottom-10">
        <div className="display-flex flex-column text-center">
          {!isLoading && numberMessagesCreated && (
            <AnimatedNumber
              start={0}
              end={numberMessagesCreated}
              className={"text-bold"}
              speed={20}
            />
          )}
          {isLoading && (
            <span className="rioglyph rioglyph-spinner spinning text-size-150pct"></span>
          )}
          <span>Total Created</span>
        </div>
        <div className="display-flex flex-column text-center">
          {!isLoading && numberMessagesSent && (
            <AnimatedNumber
              start={0}
              end={numberMessagesSent}
              className={"text-bold"}
            />
          )}
          {isLoading && (
            <span className="rioglyph rioglyph-spinner spinning text-size-150pct"></span>
          )}
          <span>Total sent</span>
        </div>
      </div>
    </div>
  );
}
