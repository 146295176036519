import ContentLoader from "@rio-cloud/rio-uikit/ContentLoader";

export default function TableBodyLoading(props) {
  const { columns } = props;

  const rows = Array(10).fill(null);

  return (
    <tbody>
      {rows.map((_, i) => (
        <tr key={i}>
          {columns.map((column) => (
            <td key={column}>
              <ContentLoader />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}
