import { useState } from "react";

export default function Footer(props) {
  const { step, steps, onPreviousStep, onNextStep, createMessage } = props;

  const [messageCreationLoading, setMessageCreationLoading] = useState(false);

  const handleCreate = () => {
    setMessageCreationLoading(true);
    createMessage();
  };

  return (
    <div className="pull-right btn-toolbar message-creation-footer">
      {step > 0 && (
        <button
          data-testid={"prev-btn"}
          type="button"
          className="btn btn-primary"
          onClick={onPreviousStep}
        >
          {"Previous"}
        </button>
      )}
      {step < steps.length - 1 && (
        <button
          data-testid={"next-btn"}
          type="button"
          className="btn btn-primary"
          onClick={onNextStep}
        >
          {"Next"}
        </button>
      )}
      {step === steps.length - 1 && (
        <button
          type="button"
          className="btn btn-primary create-message-button"
          onClick={handleCreate}
          disabled={messageCreationLoading}
        >
          {"Create"}
        </button>
      )}
    </div>
  );
}
