import { post } from "../../../../api/apiService";
import Notification from "@rio-cloud/rio-uikit/Notification";

const DeclineMessageConfirmBtn = (props) => {
  const {
    setUpdatedStatus,
    messageDetails,
    authStore,
    messageDeclineText,
    setShowDeclineMessageInput,
  } = props;

  const declineMessage = () => {
    const messageUpdate = {
      ...messageDetails,
      status: "Declined",
      timeStampStatus: new Date(),
      declined: true,
      timeStampDeclined: new Date(),
      declinedBy: authStore.user?.profile?.email,
      declinedReason: messageDeclineText,
    };
    post(`/v1/messages`, messageUpdate)
      .then(() => {
        setUpdatedStatus("declined");
        setShowDeclineMessageInput(false);
      })
      .catch((error) => {
        Notification.error(
          `Failed to update Message: ${error}`,
          "Error",
          100000
        );
      });
  };

  return (
    <button
      data-testid="message-detail-decline-btn"
      type="button"
      className="btn btn-danger margin-bottom-10 margin-right-10"
      onClick={declineMessage}
    >
      <span className="rioglyph rioglyph-remove-sign"></span>
      Decline Message
    </button>
  );
};

export default DeclineMessageConfirmBtn;
