import VehicleDetailsGeneral from "./VehicleDetailsGeneral";
import VehicleDetailsStatistics from "./VehicleDetailsStatistics";
import VehicleDetailsMessages from "./VehicleDetailsMessages";
import VehicleDetailsDevices from "./VehicleDetailsDevices";

export default function VehicleDetails(props) {
  const { vehicleId, isLoading, vehicleDetails } = props;

  return (
    <div className={"vehicle-details"}>
      <div className="display-flex align-items-center">
        <div className="margin-right-auto"></div>
      </div>
      <VehicleDetailsGeneral
        vehicleId={vehicleId}
        isLoading={isLoading}
        vehicleDetails={vehicleDetails}
      />
      <VehicleDetailsDevices
        vehicleId={vehicleId}
        isLoading={isLoading}
        vehicleDetails={vehicleDetails}
      />
      <VehicleDetailsStatistics vehicleId={vehicleId} isLoading={isLoading} />
      <VehicleDetailsMessages vehicleId={vehicleId} isLoading={isLoading} />
    </div>
  );
}
