import React, { useEffect, useState, useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes, Navigate } from "react-router";
import AuthPage from "./pages/AuthPage";
import NoMatch from "./pages/NoMatch";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import AuthHandle from "./components/Auth/AuthHandle";
import AuthRenew from "./components/Auth/AuthRenew";
import AuthLogout from "./components/Auth/AuthLogout";
import { StoreProvider, useStores } from "./stores/context";
import { observer } from "mobx-react-lite";
import { Services } from "./stores/service";
import { INIT_URL_LINK } from "./helpers/constants.jsx";
import "./App.css";
import VehiclePage from "./pages/VehiclePage";
import config from "./config";
import MessagePage from "./pages/MessagePage";
import TimelinePage from "./pages/TimeLinePage";
import StatisticsPage from "./pages/StatisticsPage";
import SupportPage from "./pages/SupportPage/SupportPage.jsx";

const RouteWrapper = observer(() => {
  const [ready, setReady] = useState(false);
  const { authStore } = useStores();
  const init = useCallback(async () => {
    await authStore.init();
  }, [authStore]);

  useEffect(() => {
    // If link is provided before Login we set a SessionStorage Item that we will redirect to after Login.
    if (
      !authStore.isLoggedIn &&
      !window.location.pathname.match(/(auth|^\/$)/i)
    ) {
      sessionStorage.setItem(
        INIT_URL_LINK,
        window.location.pathname +
          (window.location.search ? window.location.search : "")
      );
    }
    // let initURLprovided = window.location.href;
    init().then(() => setReady(true));
  }, [init, authStore.isLoggedIn]);
  // show full page loader while initialising
  if (!ready) return <div>Loading...</div>;

  if (config.AUTH_disabled) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/vehicles" element={<VehiclePage />} />
          <Route path="/vehicles/:id" element={<VehiclePage />} />
          <Route path="/messages" element={<MessagePage />} />
          <Route path="/messages/:id" element={<MessagePage />} />
          <Route exact path="/auth" element={<MessagePage />} />
          <Route exact path="/unauthorized" element={<UnauthorizedPage />} />
          <Route exact path="/auth/logout" element={<AuthLogout />} />
          <Route path="/auth/renew" element={<AuthRenew />} />
          <Route path="/auth/handle" element={<AuthHandle />} />
          <Route
            exact
            path="/"
            element={<Navigate to={authStore.getRootURIForUser()} />}
          />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    );
  }

  const renderAuthorizedRoute = (service, path, component) => {
    return (
      <Route
        path={path}
        element={
          authStore.hasAccess(service) ? (
            component
          ) : (
            <Navigate replace to={"/unauthorized"} />
          )
        }
      />
    );
  };

  return !authStore.isLoggedIn ? (
    <BrowserRouter>
      <Routes>
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/auth/logout" element={<AuthLogout />} />
        <Route path="/auth/handle" element={<AuthHandle />} />
        <Route path="/*" element={<Navigate to="/" />} />
        <Route exact path="/" element={<Navigate to="/auth" />} />
      </Routes>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Routes>
        {renderAuthorizedRoute(Services.vehicles, "/vehicles", <VehiclePage />)}
        {renderAuthorizedRoute(
          Services.vehicleDetails,
          "/vehicles/:id",
          <VehiclePage />
        )}
        {renderAuthorizedRoute(Services.messages, "/messages", <MessagePage />)}
        {renderAuthorizedRoute(
          Services.messageDetails,
          "/messages/:id",
          <MessagePage />
        )}
        {renderAuthorizedRoute(
          Services.statistics,
          "/stats/timeline",
          <TimelinePage />
        )}
        {renderAuthorizedRoute(
          Services.statistics,
          "/stats/dashboard",
          <StatisticsPage />
        )}
        <Route path="/support" element={<SupportPage />} />
        <Route exact path="/auth" element={<Navigate to="/" />} />
        <Route exact path="/unauthorized" element={<UnauthorizedPage />} />
        <Route exact path="/auth/logout" element={<AuthLogout />} />
        <Route path="/auth/renew" element={<AuthRenew />} />
        <Route path="/auth/handle" element={<AuthHandle />} />
        <Route
          exact
          path="/"
          element={<Navigate to={authStore.getRootURIForUser()} />}
        />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
});
const App = () => {
  return (
    <StoreProvider>
      <RouteWrapper />
    </StoreProvider>
  );
};

export default App;
