import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ApplicationLayout,
  ErrorState,
  NotificationsContainer,
} from "@rio-cloud/rio-uikit";
import NavBar from "../components/NavBar/NavBar";
import RMSideBar from "../components/SideBar/RMSideBar";

export default function NoMatch() {
  let navigate = useNavigate();
  return (
    <ApplicationLayout className="MyServiceLayout bg-lighter">
      <ApplicationLayout.Header>
        <NavBar />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar>
        <RMSideBar />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body className="auth">
        <NotificationsContainer />
        <ErrorState
          headline="Oh snap! Something went wrong."
          message={"We could not find the page you are looking for..."}
          buttons={[
            {
              text: <span>Go Back</span>,
              onClick: () =>
                navigate(-1) && Notification.success("You are back on track!"),
            },
          ]}
        />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
}
