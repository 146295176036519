const CancelMessageDeclineBtn = (props) => {
  const { handleCancleDecline } = props;
  return (
    <button
      data-testid="message-detail-accept-btn"
      type="button"
      className="btn btn-primary margin-bottom-10 margin-right-10"
      onClick={handleCancleDecline}
    >
      <span className="rioglyph rioglyph-revert"></span>
      Back
    </button>
  );
};

export default CancelMessageDeclineBtn;
