const vehicleList = [
  {
    id: "73541111",
    name: "Lorem",
    vin: "7354",
    status: "Active",
    labels: "PRODUCTION",
    devices: [
      {
        id: "1",
        serialnumber: "2",
      },
    ],
  },
  {
    id: "233",
    name: "Ipsum",
    vin: "7354",
    status: "Inactive",
    labels: "PRODUCTION",
    devices: [
      {
        id: 1,
        serialnumber: 2,
      },
    ],
  },
  {
    id: "895",
    name: "Lorem",
    vin: "895",
    status: "Active",
    labels: "PRODUCTION",
    devices: [
      {
        id: 1,
        serialnumber: 2,
      },
    ],
  },
  {
    id: "3456",
    name: null,
    vin: "3456",
    status: "Active",
    labels: "PRODUCTION",
    devices: [
      {
        id: 1,
        serialnumber: 2,
      },
    ],
  },
  {
    id: "2445",
    name: "Lorem",
    vin: "2445",
    status: "Active",
    labels: "PRODUCTION",
    devices: [
      {
        id: 1,
        serialnumber: 2,
      },
    ],
  },
  {
    id: "878",
    name: "Lorem",
    vin: "878",
    status: "Active",
    labels: "PRODUCTION",
    devices: [
      {
        id: 1,
        serialnumber: 2,
      },
    ],
  },
  {
    id: "2345222",
    name: "Lorem",
    vin: "2345",
    status: "Active",
    labels: "PRODUCTION",
    devices: [
      {
        id: 1,
        serialnumber: 2,
      },
    ],
  },
  {
    id: "978",
    name: "Ipsum",
    vin: "7354",
    status: "Inactive",
    labels: "PRODUCTION",
    devices: [
      {
        id: 1,
        serialnumber: 2,
      },
    ],
  },
  {
    id: "456",
    name: "Lorem",
    vin: "456",
    status: "Active",
    labels: "PRODUCTION",
    devices: [
      {
        id: 1,
        serialnumber: 2,
      },
    ],
  },
  {
    id: "4567",
    name: "Lorem",
    vin: "4567",
    status: "Active",
    labels: "PRODUCTION",
    devices: [
      {
        id: 1,
        serialnumber: 2,
      },
    ],
  },
];

export default vehicleList;
