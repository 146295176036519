import TimelineMessagesCreated from "./TimelineMessagesCreated";
import TimelineMessagesSent from "./TimelineMessagesSent";
import TimelineLength from "./TimelineLength";

export default function TimelineMessages(props) {
  const { messages, isLoading, timelineStore } = props;
  return (
    <div className="border bg-lightest shadow-hard padding-25">
      <TimelineLength timelineStore={timelineStore} />
      <TimelineMessagesCreated
        messages={messages}
        timelineStore={timelineStore}
        isLoading={isLoading}
      />
      <TimelineMessagesSent
        messages={messages}
        timelineStore={timelineStore}
        isLoading={isLoading}
      />
    </div>
  );
}
