import { observable, action, computed, makeObservable } from "mobx";
import { UserManager, WebStorageStateStore } from "oidc-client";
import { INIT_URL_LINK, ServiceRoles } from "../helpers/constants";
import { Services } from "./service";
import config from "../config";

let instance; // singleton instance
export class AuthStore {
  manager = null;
  user = null;
  roles = [];
  constructor() {
    makeObservable(this, {
      isLoggedIn: computed,
      manager: observable,
      user: observable,
      roles: observable,
      init: action,
      login: action,
      renew: action,
      completeLogin: action,
      completeRenew: action,
      completeLogout: action,
      handleUserLoaded: action,
      handleUserUnloaded: action,
      logout: action,
      handleError: action,
      hasWriteAccess: action,
    });

    this.login = this.login.bind(this);
    this.completeLogin = this.completeLogin.bind(this);
    this.logout = this.logout.bind(this);
    this.completeLogout = this.completeLogout.bind(this);
    this.handleUserLoaded = this.handleUserLoaded.bind(this);
    this.handleUserUnloaded = this.handleUserUnloaded.bind(this);
    this.hasWriteAccess = this.hasWriteAccess.bind(this);
  }
  get isLoggedIn() {
    return this.user && !this.user.expired;
  }

  async init() {
    this.manager = new UserManager({
      authority: config.AUTH_ISSUER,
      client_id: config.AUTH_CLIENT_ID,
      redirect_uri: `${config.BASEURL}/auth/handle`,
      silent_redirect_uri: `${config.BASEURL}/auth/renew`,
      automaticSilentRenew: true,
      response_type: "code",
      scope: "openid",
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      end_session_endpoint: `${config.BASEURL}/auth/logout`,
    });
    this.manager.events.addUserLoaded(this.handleUserLoaded);
    this.manager.events.addUserUnloaded(this.handleUserUnloaded);

    const user = await this.manager.getUser();
    if (user) this.handleUserLoaded(user);
  }

  login() {
    return this.manager
      .signinRedirect()
      .catch((error) => this.handleError(error));
  }

  renew() {
    return this.manager
      .signinSilent()
      .then(this.manager.storeUser.bind(this.manager))
      .catch((error) => this.handleError(error));
  }

  completeLogin() {
    return this.manager.signinRedirectCallback().catch(this.handleError);
  }

  completeRenew() {
    return this.manager.signinSilentCallback().catch(this.handleError);
  }

  completeLogout() {
    this.manager.signoutRedirectCallback().catch(this.handleError);
    this.manager.removeUser();
  }

  handleUserLoaded(user) {
    this.user = user;
    if (this.isLoggedIn) {
      this.roles = user.profile["cognito:groups"];

      localStorage.setItem("authorization", `Bearer ${user["id_token"]}`);
      this.manager.startSilentRenew();
    } else {
      this.logout();
    }
    this.manager.clearStaleState();
  }

  handleUserUnloaded() {
    this.user = null;
    this.roles = [];
  }

  logout() {
    return this.manager.signoutRedirect();
  }

  hasRole(...roles) {
    for (const role of roles) {
      if (this.roles.includes(role)) return true;
    }

    return false;
  }

  hasWriteAccess(service) {
    switch (service) {
      case Services.RemoteMessage:
        return this.hasRole(ServiceRoles.User);
      default:
        return false;
    }
  }

  hasAccess(service) {
    switch (service) {
      case Services.vehicles:
        return this.hasRole(...Object.values(ServiceRoles));
      case Services.vehicleDetails:
        return this.hasRole("Admin") || this.hasRole("User");
      case Services.messages:
        return this.hasRole(...Object.values(ServiceRoles));
      case Services.messageDetails:
        return this.hasRole("Admin") || this.hasRole("User");
      case Services.statistics:
        return this.hasRole(...Object.values(ServiceRoles));
      default:
        return this.isLoggedIn;
    }
  }

  getRootURIForUser() {
    if (!this.isLoggedIn) {
      return "/auth";
    }

    if (sessionStorage.getItem(INIT_URL_LINK) !== null) {
      const redirectLink = sessionStorage.getItem(INIT_URL_LINK);
      // Timeout is necessary because React runs the code multiple times, so we have no control over when the deletion happened.
      setTimeout(() => sessionStorage.removeItem(INIT_URL_LINK), 7000);
      return redirectLink;
    }

    if (this.hasAccess(Services.RemoteMessage)) {
      return "/vehicles";
    }
    return "/unauthorized"; // no valid access
  }

  handleError(error) {
    console.error("Auth Error", error);
  }

  static instance() {
    if (!instance) {
      instance = new AuthStore();
    }
    return instance;
  }
}
