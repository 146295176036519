import SeverityLabelRenderer from "./SeverityLabelRenderer";

const TableCellSeverityRenderer = (props) => {
  const { columnItem, dataField } = props;

  return (
    <td data-field={dataField}>
      <div
        className={`border text-medium text-center margin-auto padding-right-15 padding-top-5 padding-left-15 width-100 padding-bottom-5 border-width-2 rounded-large ${SeverityLabelRenderer(
          columnItem
        )}`}
      >
        {columnItem}
      </div>
    </td>
  );
};

export default TableCellSeverityRenderer;
