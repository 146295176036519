const DeclineMessageBtn = (props) => {
  const { handleCancleDecline, authStore, messageDetails } = props;

  const showDeclineMessageBtn = () => {
    if (
      authStore.hasRole("Admin") &&
      !messageDetails?.declined &&
      !messageDetails?.timestampSend &&
      !messageDetails?.approved &&
      !(messageDetails?.status === "Failed")
    ) {
      return true;
    }
    if (
      authStore.hasRole("User") &&
      !messageDetails?.declined &&
      !messageDetails?.timestampSend &&
      !messageDetails?.approved &&
      !(messageDetails?.status === "Failed")
    ) {
      return true;
    }

    if (authStore.hasRole("Guest")) {
      return false;
    }
  };

  return showDeclineMessageBtn() ? (
    <button
      data-testid="message-detail-decline-btn"
      type="button"
      className="btn btn-primary margin-bottom-10 margin-right-10"
      onClick={handleCancleDecline}
    >
      <span className="rioglyph rioglyph rioglyph rioglyph-remove-sign"></span>
      Decline
    </button>
  ) : null;
};

export default DeclineMessageBtn;
