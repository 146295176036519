import ButtonDropdown from "@rio-cloud/rio-uikit/ButtonDropdown";

const options = [10, 25, 50, 100, 200];

export default function TableLength(props) {
  const { pageSize, setPageSize, setCurrentPage } = props;

  const handlePageSize = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const pageSizeValues = options.map((option) => ({
    value: option,
    onSelect: () => {
      handlePageSize(option);
    },
  }));
  return (
    <ButtonDropdown
      title={pageSize}
      items={pageSizeValues}
      id="table-length"
      data-testid="table-length"
    />
  );
}
