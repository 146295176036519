import omit from "lodash/fp/omit";
import { get } from "lodash";
import isEmpty from "lodash/fp/isEmpty";
import TableBodyLoading from "../Loading/TableBodyLoading";
import TableCellRenderer from "./helpers/TableRenderers/TableCellRenderer";
import NotFoundState from "@rio-cloud/rio-uikit/NotFoundState";
import { sortByProperty } from "@rio-cloud/rio-uikit/SortUtils";

const TableBody = (props) => {
  const {
    columnOrder,
    sortBy,
    sortDir,
    columnLabels,
    searchValue,
    vehicleList,
    hiddenColumns,
    isLoading,
    rowClicked,
  } = props;

  // filter for hidden columns
  const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));

  // filter data to omit hidden columns
  const withoutHidden = omit(hiddenColumns);
  const filteredRows = vehicleList.map((vehicle) => ({
    ...withoutHidden(vehicle),
  }));

  // in case a search value is given, filter the data accordingly
  const searchResult = !searchValue
    ? filteredRows
    : filteredRows.filter((row) => {
        return columns.some((col) => {
          if (
            row.hasOwnProperty(col) &&
            row[col] !== undefined &&
            row[col] !== null
          ) {
            return row[col]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase());
          }
          return false;
        });
      });

  // Sort rows according to the sortBy and sortDir settings
  const rows = sortBy
    ? sortByProperty(searchResult, sortBy, sortDir)
    : searchResult;

  const isHighlightedRow = (selectedRow, currentRow) => {
    return selectedRow &&
      selectedRow.toString() === get(currentRow, props.rowKey).toString()
      ? "active"
      : "";
  };

  const handleRowClick = (...params) => {
    rowClicked(...params);
  };
  return (
    <>
      {isLoading ? (
        <TableBodyLoading columns={columns} />
      ) : (
        <tbody>
          {isEmpty(rows) && (
            <tr>
              <td colSpan={columns.length}>
                <NotFoundState
                  outerClassName="border-none"
                  headline="Nothing found"
                  message="Please refine your search"
                />
              </td>
            </tr>
          )}
          {rows.map((row, index) => {
            const rowWithSimulatedData = {
              ...row,
              isDeviceSimulatedAsOffline: Math.random() > 0.75,
            };
            return (
              <tr
                key={index}
                className={`cursor-default ${isHighlightedRow(
                  props?.selectedRow,
                  row
                )}`}
                onClick={(e) => handleRowClick(row, e)}
              >
                {columns.map((col) => (
                  <TableCellRenderer
                    dataField={columnLabels[col]}
                    column={col}
                    columnItem={rowWithSimulatedData[col]}
                    row={row}
                    isDeviceSimulatedAsOffline={
                      rowWithSimulatedData.isDeviceSimulatedAsOffline
                    }
                  />
                ))}
              </tr>
            );
          })}
        </tbody>
      )}
    </>
  );
};

export default TableBody;
