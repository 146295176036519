import ContentLoader from "@rio-cloud/rio-uikit/ContentLoader";
import { get } from "../../../api/apiService";
import { useState, useEffect } from "react";
import config from "../../../config";

export default function VehicleDetailsStatistics(props) {
  const [isLoading, setIsLoading] = useState();
  const [numberMessagesSent, setNumberMessagesSent] = useState(0);
  const [numberMessagesTotal, setNumberMessagesTotal] = useState(0);
  const { vehicleId } = props;

  const countSentMessages = (messages) => {
    if (messages) {
      const sentMessages = messages.filter((message) => {
        return message.status === "Sent" || message.status === "Delievered";
      });
      return sentMessages.length;
    }
  };

  const countTotalMessages = (messages) => {
    if (messages) {
      return messages.length;
    }
  };

  useEffect(() => {
    if (config.AUTH_disabled) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
      get(`/v1/messages?s=${vehicleId}`).then((data) => {
        setNumberMessagesSent(countSentMessages(data.results));
        setNumberMessagesTotal(countTotalMessages(data.results));
        setIsLoading(false);
      });
    }
  }, [vehicleId]);
  return (
    <>
      <h5>
        <span class="rioglyph rioglyph-stats text-size-20 margin-right-10"></span>
        Statistics
      </h5>
      <div
        data-testid="vehicle-details-statistics-section"
        className="breakline margin-bottom-20"
      />
      <div className="row">
        <div className="col-md-6">
          <div className="margin-bottom-10">
            <label className="display-block">Total Messages:</label>

            {isLoading ? (
              <ContentLoader />
            ) : (
              <span className="text-primary"> {numberMessagesTotal}</span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="margin-bottom-10">
            <label className="display-block">Sent Messages:</label>

            {isLoading ? (
              <ContentLoader />
            ) : (
              <span className="text-primary"> {numberMessagesSent}</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
