import { useNavigate } from "react-router-dom";
import { DetailsSideBar } from "../../Common/DetailsSideBar/DetailsSideBar";
import MessageDetails from "./MessageDetails";
import { get } from "../../../api/apiService";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Notification from "@rio-cloud/rio-uikit/Notification";

export default function MessageDetailSideBar(props) {
  const { messageId } = props;

  const location = useLocation();

  const [messageDetails, setMessageDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVehicle, setIsLoadingVehicle] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState(null);
  const [updateButtonSpinning, setUpdateButtonSpinning] = useState(false);
  const [errorNotification, setErrorNotification] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.messageCreated) {
      Notification.success("Message created");
    }
  }, [location.state?.messageCreated, updatedStatus]);

  useEffect(() => {
    if (updatedStatus && !errorNotification) {
      Notification.success(`Message ${messageId} ${updatedStatus}.`);
    }
    // eslint-disable-next-line
  }, [updatedStatus, messageId]);

  useEffect(() => {
    if (errorNotification) {
      Notification.error(errorNotification);
    }
  }, [updatedStatus, messageId, errorNotification]);

  const loadMessages = (reloadUI) => {
    if (reloadUI) {
      setIsLoading(true);
    }
    if (!reloadUI) {
      setUpdateButtonSpinning(true);
    }

    get(`/v1/messages/${messageId}`)
      .then((data) => {
        setMessageDetails(data);
        if (!reloadUI) {
          setUpdateButtonSpinning(false);
          Notification.success(`Updates fetched.`);
        }
        if (reloadUI) {
          setIsLoading(false);
        }

        setUpdatedStatus(null);
      })
      .catch((error) => {
        Notification.error(`Failed to fetch: ${error}`, "Error", 100000);
      });
  };

  useEffect(() => {
    loadMessages(true);
    // eslint-disable-next-line
  }, [messageId, updatedStatus, errorNotification]);

  useEffect(() => {
    if (messageDetails.vehicleId) {
      setIsLoadingVehicle(true);
      get(`/v1/devices/${messageDetails.vehicleId}`)
        .then((data) => {
          setVehicleDetails(data);
          setIsLoadingVehicle(false);
        })
        .catch((error) => {
          Notification.error(`Failed to fetch: ${error}`, "Error", 100000);
        });
    }
  }, [messageDetails.vehicleId]);

  const onClose = () => {
    navigate(`/messages`);
  };

  return (
    <DetailsSideBar
      title={
        <h4>
          <span class="rioglyph rioglyph-detail-view-info text-size-30 margin-right-10"></span>
          Message Details
        </h4>
      }
      vehicleId={messageId}
      onClose={onClose}
    >
      <MessageDetails
        messageId={messageId}
        isLoading={isLoading}
        messageDetails={messageDetails}
        isLoadingVehicle={isLoadingVehicle}
        vehicleDetails={vehicleDetails}
        setUpdatedStatus={setUpdatedStatus}
        setErrorNotification={setErrorNotification}
        loadMessages={loadMessages}
        updateButtonSpinning={updateButtonSpinning}
      />
    </DetailsSideBar>
  );
}
