import axios from "axios";

const BASE_URL = "https://yvck0iwcfc.execute-api.eu-west-1.amazonaws.com";

export const get = async (endpoint) => {
  const jwt = window.localStorage.getItem("authorization");

  try {
    const response = await axios.get(BASE_URL + endpoint, {
      headers: {
        Authorization: jwt,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const post = async (endpoint, data) => {
  const jwt = window.localStorage.getItem("authorization");

  try {
    const response = await axios.post(BASE_URL + endpoint, data, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: jwt,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
