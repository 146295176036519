import ContentLoader from "@rio-cloud/rio-uikit/ContentLoader";
import Collapse from "@rio-cloud/rio-uikit/Collapse";
import { useEffect, useState } from "react";
import convertDateFormat from "../../../helpers/convertDate";
import ReleaseNotes from "@rio-cloud/rio-uikit/ReleaseNotes";

export const MessageDetailsEvents = (props) => {
  const { isLoading, messageDetails } = props;

  const timeStamps = [
    "timeStampCreated",
    "timeStampDeclined",
    "timeStampApproved",
    "timeStampInTransfer",
    "timeStampFailed",
    "timestampSend",
  ];

  const [showCollapse, setShowCollapse] = useState(false);
  const [events, setEvents] = useState({});

  const loadEvents = () => {
    if (!messageDetails) {
      return;
    }
    timeStamps.forEach((timeStamp) => {
      if (messageDetails[timeStamp]) {
        setEvents((events) => ({
          ...events,
          ...createTimeStamp(timeStamp),
        }));
      }
    });
  };

  const createTimeStamp = (timeStamp) => {
    switch (timeStamp) {
      case "timeStampCreated":
        return {
          Created: {
            date: convertDateFormat(messageDetails.timeStampCreated),
            content: (
              <div>
                Created by: <br />
                <span className="rioglyph rioglyph-user margin-left-10 margin-right-5"></span>
                {messageDetails?.createdBy}.
              </div>
            ),
          },
        };
      case "timeStampDeclined":
        return {
          Declined: {
            date: convertDateFormat(messageDetails.timeStampDeclined),
            content: (
              <>
                <div>
                  Declined by: <br />
                  <span className="rioglyph rioglyph-user margin-left-10 margin-right-5"></span>
                  {messageDetails?.declinedBy}. <br />
                </div>
                <div className="margin-top-5">
                  Decline Reason: <br />
                  {messageDetails.declinedReason}
                </div>
              </>
            ),
          },
        };
      case "timeStampApproved":
        return {
          Approved: {
            date: convertDateFormat(messageDetails.timeStampApproved),
            content: (
              <div>
                Approved by:
                <br />
                <span className="rioglyph rioglyph-user margin-left-10 margin-right-5"></span>{" "}
                {messageDetails?.approvedBy}.
              </div>
            ),
          },
        };
      case "timeStampInTransfer":
        return {
          "In Transfer": {
            date: convertDateFormat(messageDetails.timeStampInTransfer),
            content: (
              <div>
                Issued to send by: <br />
                <span className="rioglyph rioglyph-user margin-left-10 margin-right-5"></span>{" "}
                {messageDetails?.sentBy}.
              </div>
            ),
          },
        };
      case "timeStampFailed":
        return {
          Failed: {
            date: convertDateFormat(messageDetails.timeStampFailed),
            content: (
              <div>
                Failed to send because: <br />
                <span className="rioglyph rioglyph-warning-sign margin-left-10 margin-right-5"></span>{" "}
                Error: {messageDetails?.sendingFailedErrorMessage}
              </div>
            ),
          },
        };
      case "timestampSend":
        return {
          Sent: {
            date: convertDateFormat(messageDetails.timestampSend),
            content: (
              <div>
                <span className="rioglyph rioglyph-antenna margin-left-5 margin-right-5"></span>
                Message has been sent to the vehicle.
              </div>
            ),
          },
        };
      default:
        return;
    }
  };

  useEffect(() => {
    loadEvents();
    // eslint-disable-next-line
  }, [messageDetails, isLoading]);

  return (
    <div data-testid="message-details-events-section">
      <h5
        className="messageDetailHeading"
        onClick={() => setShowCollapse((state) => !state)}
      >
        <span className="rioglyph rioglyph-time text-size-20 margin-right-10"></span>{" "}
        <span className="margin-right-5">Events</span>
        <span
          className={
            showCollapse
              ? "rioglyph rioglyph-chevron-down"
              : "rioglyph rioglyph-chevron-right"
          }
        ></span>
      </h5>
      <div className="breakline margin-bottom-20" />

      <Collapse in={showCollapse}>
        <div>
          {isLoading ? (
            <ContentLoader />
          ) : (
            <ReleaseNotes releaseNotes={events} />
          )}
        </div>
      </Collapse>
    </div>
  );
};
