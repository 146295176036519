import { useEffect, useState } from "react";
import PieChart from "@rio-cloud/rio-uikit/PieChart";

export default function StatisticsState(props) {
  const { messages, isLoading } = props;

  const [chartData, setChartData] = useState();

  const states = ["Low", "Medium", "High"];

  const colors = [
    "color-coldplay-moos",
    "color-warmup-cherokee",
    "color-warmup-cerise",
  ];

  useEffect(() => {
    setChartData(createChartData());
    // eslint-disable-next-line
  }, [messages]);

  const filterByState = (state) => {
    if (messages) {
      const filteredMessages = messages?.filter((message) => {
        return message.severity === state;
      });
      return filteredMessages.length;
    }
  };

  const createChartData = () => {
    const chartData = states.map((status, index) => {
      return {
        name: status,
        count: filterByState(status),
        color: colors[index],
      };
    });

    return chartData;
  };

  return (
    <div
      className={`shadow-hard margin-bottom-0 border bg-lightest display-flex flex-column ${
        isLoading ? "height-300" : ""
      }`}
    >
      <h5 className="margin-left-10">Severity States</h5>
      {isLoading && (
        <div className="align-self-center margin-auto display-flex align-items-center">
          <span className="margin-right-5 text-size-h4">
            Loading Graphics...
          </span>
          <span className="rioglyph rioglyph-tire spinning text-size-h1"></span>
        </div>
      )}
      {!isLoading && (
        <PieChart
          data={chartData}
          dataKey={(entry) => entry.count}
          outerRadius={90}
          height={300}
          width="100%"
          align="left"
          filled
        />
      )}
    </div>
  );
}
