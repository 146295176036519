import React from "react";
import { usePagination, DOTS } from "./usePagination";

const Pages = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <nav
      data-testid={"pages-navigation"}
      className={"pagination-wrapper--center"}
    >
      <ul className={"pagination"}>
        <li
          key={"prev-page-button"}
          className={currentPage === 1 ? "disabled" : ""}
          onClick={onPrevious}
        >
          <span className="rioglyph rioglyph-chevron-left"></span>
        </li>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li key={pageNumber}>
                <span className="rioglyph rioglyph-option-horizontal"></span>
              </li>
            );
          }

          return (
            <li
              key={`page${pageNumber}`}
              id={`page${pageNumber}`}
              className={pageNumber === currentPage ? "bg-light" : ""}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          key={"next-button"}
          className={currentPage === lastPage ? "disabled" : ""}
          onClick={onNext}
        >
          <span className="rioglyph rioglyph-chevron-right"></span>
        </li>
      </ul>
    </nav>
  );
};

export default Pages;
