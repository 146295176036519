import ApplicationHeader from "@rio-cloud/rio-uikit/ApplicationHeader";
import { NavLink, useNavigate } from "react-router-dom";
import ActionBarItem from "@rio-cloud/rio-uikit/ActionBarItem";
import Notification from "@rio-cloud/rio-uikit/Notification";
import { useStores } from "../../stores/context";

const navItems = [
  {
    key: "1",
    route: (
      <NavLink to="/" className={"active"}>
        Remote Message
      </NavLink>
    ),
  },
];

const label = () => {
  return (
    <div className={"text-left"} style={{ pointerEvents: "none" }}>
      RM ADMIN
    </div>
  );
};

export default function NavBar() {
  const { authStore } = useStores();
  const navigate = useNavigate();

  const AccountMenuComponent = (
    <ActionBarItem id="accountMenu" hidePopoverOnClick={false}>
      <ActionBarItem.Icon>
        <span
          data-testid={"navbar-user-icon"}
          className="icon rioglyph rioglyph-user"
        />
      </ActionBarItem.Icon>
      <ActionBarItem.Popover>
        <ActionBarItem.List>
          <ActionBarItem.ListItem>
            <div
              data-testid="user-email"
              className="text-medium text-color-darker"
            >
              {authStore.user?.profile?.email}
            </div>
          </ActionBarItem.ListItem>
          <ActionBarItem.ListSeparator />
          <ActionBarItem.ListItem className="padding-0">
            <div className="container">
              <div className="row">
                <div className="text-medium text-size-14">Roles:</div>
                <div data-testid="user-roles" className="col-md-12">
                  {authStore.roles
                    .filter((role) => !role.startsWith("eu-west"))
                    .map((role) => {
                      return <div key={role}>{role}</div>;
                    })}
                </div>
              </div>
            </div>
          </ActionBarItem.ListItem>
          <ActionBarItem.ListSeparator />
          <ActionBarItem.ListItem className="padding-0">
            <button
              data-testid="navbar-logout-btn"
              className="btn btn-link btn-link-inline text-color-dark"
              onClick={() => {
                Notification.info("Logout");

                navigate("/auth/logout");
              }}
            >
              <span className="rioglyph rioglyph-off margin-right-5 text-size-16 text-color-dark" />
              <span>Logout</span>
            </button>
          </ActionBarItem.ListItem>
        </ActionBarItem.List>
      </ActionBarItem.Popover>
    </ActionBarItem>
  );

  return (
    <ApplicationHeader
      className={"RMApplicationHeader"}
      label={label()}
      navItems={navItems}
      actionBarItems={[AccountMenuComponent]}
      homeRoute={<NavLink to="/" />}
    />
  );
}
