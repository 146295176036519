import { post } from "../../../../api/apiService";
import Notification from "@rio-cloud/rio-uikit/Notification";

const ApproveMessageBtn = (props) => {
  const { setUpdatedStatus, authStore, messageDetails } = props;

  const approveMessage = () => {
    const messageUpdate = {
      ...messageDetails,
      approved: true,
      status: "Approved",
      timeStampStatus: new Date(),
      timeStampApproved: new Date(),
      approvedBy: authStore.user?.profile?.email,
    };
    post(`/v1/messages`, messageUpdate)
      .then(() => {
        setUpdatedStatus("approved");
      })
      .catch((error) => {
        Notification.error(
          `Failed to update Message: ${error}`,
          "Error",
          100000
        );
      });
  };

  const showApproveMessageBtn = () => {
    if (
      authStore.hasRole("Admin") &&
      !messageDetails?.approved &&
      !messageDetails?.timestampSend &&
      !messageDetails?.declined &&
      !(messageDetails?.status === "Failed")
    ) {
      return true;
    }
    if (
      authStore.hasRole("User") &&
      !messageDetails?.approved &&
      !messageDetails?.timestampSend &&
      !messageDetails?.declined &&
      !(messageDetails?.status === "Failed") &&
      messageDetails.createdBy !== authStore.user.profile.email
    ) {
      return true;
    }

    if (authStore.hasRole("Guest")) {
      return false;
    }
  };

  return showApproveMessageBtn() ? (
    <button
      data-testid="message-detail-accept-btn"
      type="button"
      className="btn btn-primary margin-bottom-10 margin-right-10"
      onClick={approveMessage}
    >
      <span className="rioglyph rioglyph rioglyph-ok-sign"></span>
      Accept
    </button>
  ) : null;
};

export default ApproveMessageBtn;
