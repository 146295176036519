import SortArrows from "@rio-cloud/rio-uikit/SortArrows";
import classNames from "classnames";

export default function TableHead(props) {
  const tableHeadClassNames = classNames("user-select-none", "sort-column");

  const {
    column,
    sortBy,
    sortDir,
    columnLabels,
    setSortBy,
    setSortDir,
    getSortDir,
  } = props;

  const handleSortChange = (event) => {
    const newSortBy = event.currentTarget.getAttribute("data-sortby");
    setSortBy(newSortBy);
    setSortDir(getSortDir(sortDir, newSortBy, sortBy));
  };

  return (
    <th
      key={column}
      className={tableHeadClassNames}
      onClick={handleSortChange}
      data-field={column}
      data-sortby={column}
      title={columnLabels}
    >
      <span>
        {sortBy === column ? (
          <SortArrows direction={sortDir} />
        ) : (
          <SortArrows />
        )}
        <span>{columnLabels}</span>
      </span>
    </th>
  );
}
