import { useEffect, useState } from "react";
import AreaChart from "@rio-cloud/rio-uikit/AreaChart";
import Area from "@rio-cloud/rio-uikit/Area";
import { observer } from "mobx-react-lite";

const TimeLineGraph = observer((props) => {
  const { messages, isLoading, timelineStore, sortByProperty, title } = props;

  const [filteredMessages, setFilterMessages] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [startDate, setStartDate] = useState();

  const sortByDateAscending = (array) => {
    return array.sort(function (a, b) {
      var dateA = new Date(a.date.split(".").reverse().join("-"));
      var dateB = new Date(b.date.split(".").reverse().join("-"));

      return dateA - dateB;
    });
  };

  const generateStartDate = () => {
    const dateObj = new Date();

    dateObj.setDate(dateObj.getDate() - timelineStore.getFilter);

    return dateObj;
  };

  function generateObjectsForMissingDates(array) {
    const currentDate = new Date();

    const dateMap = new Map(array.map((obj) => [obj.date, obj]));

    let startDateCopy = new Date(startDate);

    while (startDateCopy <= currentDate) {
      const formattedDate = startDateCopy
        .toLocaleDateString("en-GB")
        .replace(/\//g, ".");
      if (!dateMap.has(formattedDate)) {
        array.push({ date: formattedDate, value: 0 });
      }

      startDateCopy.setDate(startDateCopy.getDate() + 1);
    }

    return sortByDateAscending(array);
  }

  const filterByDate = () => {
    if (!messages) {
      return;
    }

    const filteredMessages = messages.filter((message) => {
      return Date.parse(message[sortByProperty]) > Date.parse(startDate);
    });
    return filteredMessages;
  };

  const createChartData = () => {
    if (!filteredMessages) {
      return;
    }
    const chartData = filteredMessages.reduce(
      (acc, message) => {
        const messageDate = `${new Date(message[sortByProperty]).getDate()}.${
          new Date(message[sortByProperty]).getMonth() + 1
        }.${new Date(message[sortByProperty]).getFullYear()}`;
        const existingData = acc.find((data) => {
          return data.date === messageDate;
        });

        if (existingData) {
          existingData.value++;
        } else acc.splice(-1, 0, { date: messageDate, value: 1 });
        return acc;
      },
      [
        {
          date: `${new Date(Date.now()).getDate()}.${
            new Date(Date.now()).getMonth() + 1
          }.${new Date(Date.now()).getFullYear()}`,
          value: 0,
        },
        {
          date: `${new Date(startDate).getDate()}.${
            new Date(startDate).getMonth() + 1
          }.${new Date(startDate).getFullYear()}`,
          value: 0,
        },
      ]
    );

    const filledDates = generateObjectsForMissingDates(chartData);

    return filledDates;
  };

  useEffect(() => {
    setFilterMessages(filterByDate());
    // eslint-disable-next-line
  }, [messages, timelineStore, startDate]);

  useEffect(() => {
    setStartDate(generateStartDate());
    // eslint-disable-next-line
  }, [timelineStore.getFilter]);

  useEffect(() => {
    setChartData(createChartData());
    // eslint-disable-next-line
  }, [filteredMessages, timelineStore.getFilter]);

  return (
    <div className="margin-bottom-50 height-250 padding-bottom-25 max-width-1200 margin-auto">
      <h5>{title}</h5>
      {isLoading && (
        <div className="margin-auto display-flex flex-row align-items-center justify-content-center height-100pct">
          <span className="margin-right-5 text-size-h4">
            Loading Graphics...
          </span>
          <span className="rioglyph rioglyph-tire spinning text-size-h1"></span>
        </div>
      )}
      {!isLoading && filteredMessages.length === 0 && (
        <div className="margin-auto display-flex flex-row align-items-center justify-content-center height-100pct">
          <span className="margin-right-10 text-size-h4">
            No messages in selected timeframe
          </span>
          <span className="rioglyph  rioglyph-empty text-size-h4"></span>
        </div>
      )}
      {!isLoading && filteredMessages.length >= 1 && (
        <AreaChart
          data={chartData}
          label={(entry) => entry.date}
          xAxisOptions={{
            label: {
              value: "Date",
              position: "insideBottom",
              offset: -10,
            },
            padding: { left: 30, right: 30 },
          }}
          areas={[<Area key="area" dataKey={(entry) => entry.value} />]}
          dataKey={(entry) => entry.date}
          showYAxis={true}
          yAxisOptions={{
            label: { value: "Amount", angle: -90, position: "insideLeft" },
          }}
        />
      )}
    </div>
  );
});

export default TimeLineGraph;
