import PieChart from "@rio-cloud/rio-uikit/PieChart";
import { useEffect, useState } from "react";

export default function StatisticsType(props) {
  const { messages, isLoading } = props;

  const [chartData, setChartData] = useState();

  const types = ["Technical Information", "Recall", "Service Information"];

  const colors = [
    "color-coldplay-fountain",
    "color-warmup-cerise",
    "color-coldplay-bermuda",
  ];

  useEffect(() => {
    setChartData(createChartData());
    // eslint-disable-next-line
  }, [messages]);

  const filterByType = (type) => {
    if (messages) {
      const filteredMessages = messages?.filter((message) => {
        return message.type === type;
      });
      return filteredMessages?.length;
    }
  };

  const createChartData = () => {
    const chartData = types.map((type, index) => {
      return {
        name: type,
        count: filterByType(type),
        color: colors[index],
      };
    });
    return chartData;
  };

  return (
    <div
      className={`shadow-hard margin-bottom-0 border bg-lightest display-flex flex-column ${
        isLoading ? "height-300" : ""
      }`}
    >
      <h5 className="margin-left-10">Message Types</h5>
      {isLoading && (
        <div className="align-self-center margin-auto display-flex align-items-center">
          <span className="margin-right-5 text-size-h4">
            Loading Graphics...
          </span>
          <span className="rioglyph rioglyph-tire spinning text-size-h1"></span>
        </div>
      )}

      {!isLoading && (
        <PieChart
          data={chartData}
          dataKey={(entry) => entry.count}
          outerRadius={90}
          height={300}
          width="100%"
          align="left"
          filled
        />
      )}
    </div>
  );
}
