import React, { useEffect, useState } from 'react';
import { useStores } from '../../stores/context';
import { Navigate } from 'react-router';

const AuthRenew = () => {
    const [loading, setLoading] = useState(true);
    const { authStore } = useStores();
    useEffect(() => {
        authStore.completeRenew().then(() => setLoading(false));
    }, [authStore]);
    return loading ? <div>Loading…</div>: <Navigate to={authStore.getRootURIForUser()} />;
};

export default AuthRenew;
