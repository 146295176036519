import NavBar from "../../components/NavBar/NavBar";
import RMSideBar from "../../components/SideBar/RMSideBar";
import { NotificationsContainer } from "@rio-cloud/rio-uikit";
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";

const SupportPage = () => {
  return (
    <ApplicationLayout className="MyServiceLayout bg-lighter">
      <ApplicationLayout.Header>
        <NavBar />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar>
        <RMSideBar />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body>
        <NotificationsContainer />
        <div
          className={`shadow-hard bg-lightest padding-15 border max-width-700 margin-auto`}
        >
          <h2 className="margin-top-0 margin-bottom-20">Support</h2>

          <h5>Contact</h5>
          <p>
            For general questions or if you need specific permissions, please
            get in touch by sending an email. <br /> In case you're requesting
            permissions please include a brief explanation why you require the
            permissions. <br /> <br />
            You can contact us at:{" "}
            <a href="mailto:phillip.walter@man.eu">phillip.walter@man.eu</a>
          </p>
          <h5>Permissions</h5>
          <p>
            If you've been granted new permissions, kindly log out and log back
            in. <br />
            Your updated roles should then be visible when clicking on the{" "}
            <span className="rioglyph rioglyph-user margin-right-5 margin-left-5"></span>{" "}
            user symbol in the top-right corner.
          </p>
          <p></p>
        </div>
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
};

export default SupportPage;
