import convertDateFormat from "../../../../../helpers/convertDate";
import TableCellDeviceRenderer from "./TableCellDeviceRenderer";
import TableCellIdRenderer from "./TableCellIdRenderer";
import TableCellSeverityRenderer from "./TableCellSeverityRenderer";
import TableCellStatusRenderer from "./TableCellStatusRenderer";
import TableCellDriverRenderer from "./TableCellDriverRenderer";
import TableCellMessageTypeRenderer from "./TableCellMessageTypeRenderer";
import TableCellTitleRenderer from "./TableCellTitleRenderer";
import TableCellVINRenderer from "./TableCellVINRenderer";
import TableCellActivityStateRenderer from "./TableCellActivityStateRenderer";
import TableCellVehicleNameRenderer from "./TableCellVehicleNameRenderer";

const TableCellRenderer = (props) => {
  const { column, columnItem, dataField, isDeviceSimulatedAsOffline, row } =
    props;

  switch (column) {
    case "timeStampStatus":
      return <td data-field={dataField}>{convertDateFormat(columnItem)}</td>;
    case "id":
      return <TableCellIdRenderer id={columnItem} dataField={dataField} />;
    case "title":
      return (
        <TableCellTitleRenderer title={columnItem} dataField={dataField} />
      );
    case "type":
      return (
        <TableCellMessageTypeRenderer id={columnItem} dataField={dataField} />
      );
    case "devices":
      return <TableCellDeviceRenderer id={columnItem} dataField={dataField} />;
    case "severity":
      return (
        <TableCellSeverityRenderer
          columnItem={columnItem}
          dataField={dataField}
        />
      );
    case "status":
      return (
        <TableCellStatusRenderer
          columnItem={columnItem}
          dataField={dataField}
        />
      );
    case "driver":
      return (
        <TableCellDriverRenderer
          columnItem={columnItem}
          dataField={dataField}
          isDeviceSimulatedAsOffline={isDeviceSimulatedAsOffline}
        />
      );
    case "vin":
      return (
        <TableCellVINRenderer columnItem={columnItem} dataField={dataField} />
      );
    case "activityState":
      return (
        <TableCellActivityStateRenderer
          isDeviceSimulatedAsOffline={isDeviceSimulatedAsOffline}
          row={row}
        />
      );
    case "name":
      return (
        <TableCellVehicleNameRenderer
          column={column}
          columnItem={columnItem}
          dataField={dataField}
          row={row}
        />
      );
    default:
      return <td data-field={dataField}>{columnItem}</td>;
  }
};

export default TableCellRenderer;
