const UpdateMessageBtn = (props) => {
  const { updateStatus, updateButtonSpinning, authStore, messageDetails } =
    props;

  const showUpdateMessage = () => {
    if (
      authStore.hasRole("Admin") &&
      messageDetails?.status === "In_transfer"
    ) {
      return true;
    }
    if (authStore.hasRole("User") && messageDetails?.status === "In_transfer") {
      return true;
    } else if (authStore.hasRole("Guest")) {
      return false;
    }
  };

  return showUpdateMessage() ? (
    <button
      data-testid="message-detail-update-btn"
      type="button"
      className="btn btn-primary margin-bottom-10"
      onClick={() => updateStatus(true)}
    >
      <span
        className={`rioglyph rioglyph-refresh ${
          updateButtonSpinning ? "spinning" : ""
        }`}
      ></span>
      Update Status
    </button>
  ) : null;
};

export default UpdateMessageBtn;
