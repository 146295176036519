import { useState, useEffect } from "react";

function useResizableElementWidth(elRef, props) {
  const [width, setWidth] = useState(0);

  const handleCellResize = () => {
    if (!elRef?.current?.clientWidth) {
      return;
    }
    setWidth(elRef.current.clientWidth);
  };

  useEffect(() => {
    handleCellResize();

    const element = elRef.current;

    if (!element) return;

    const observer = new ResizeObserver(() => {
      handleCellResize();
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [elRef, props?.id]);

  return width;
}

export default useResizableElementWidth;
