import TimeLineGraph from "./TimelineGraph";

const TimelineMessagesCreated = (props) => {
  const { messages, isLoading, timelineStore } = props;

  const sortByProperty = "timeStampCreated";

  const title = "Messages Created";

  return (
    <TimeLineGraph
      messages={messages}
      timelineStore={timelineStore}
      isLoading={isLoading}
      sortByProperty={sortByProperty}
      title={title}
    />
  );
};

export default TimelineMessagesCreated;
