import { useNavigate } from "react-router-dom";
import Table from "../../Common/Table/Table";
import config from "../../../config";
import { useStores } from "../../../stores/context";
import Notification from "@rio-cloud/rio-uikit/Notification";

export default function MessageTable(props) {
  let navigate = useNavigate();

  const { authStore } = useStores();

  const {
    toolbarElement,
    selectedRow,
    isLoading,
    messagesList,
    filterStore,
    paginationStore,
    messageTableSettings,
  } = props;

  const {
    defaultColumnOrder,
    columnLabels,
    disabledColumns,
    columnDetails,
    toolbarOptions,
  } = messageTableSettings;

  const rowClicked = (row) => {
    if (
      authStore.hasRole("User") ||
      authStore.hasRole("Admin") ||
      config.AUTH_disabled
    ) {
      navigate(`/messages/${row.id}`);
    } else
      Notification.error(
        "Insufficient permissions to access Message Details",
        "No Permission",
        10000
      );
  };
  return (
    <Table
      defaultColumnOrder={defaultColumnOrder}
      columnLabels={columnLabels}
      data={messagesList}
      toolbarOptions={toolbarOptions}
      defaultColumnDetails={columnDetails}
      disabledColumns={disabledColumns}
      isLoading={isLoading}
      selectedRow={selectedRow}
      rowKey="id"
      rowClicked={rowClicked}
      toolbarElement={toolbarElement}
      filterStore={filterStore}
      paginationStore={paginationStore}
    />
  );
}
