import NavBar from "../components/NavBar/NavBar";
import RMSideBar from "../components/SideBar/RMSideBar";
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import VehicleTable from "../components/Vehicle/Table/VehicleTable";
import { useParams } from "react-router-dom";
import VehicleDetailSideBar from "../components/Vehicle/SideBar/VehicleDetailSidebar";
import { NotificationsContainer } from "@rio-cloud/rio-uikit";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PaginationStore } from "../stores/pagination";
import { get } from "../api/apiService";
import { useEffect, useState } from "react";
import { FilterStore } from "../stores/filter";
import config from "../config";
import mockVehicleData from "../assets/MockData/Vehicles";
import Pagination from "../components/Common/Pagination/Pagination";
import Notification from "@rio-cloud/rio-uikit/Notification";
import { vehicleTableSettings } from "../helpers/constants";
import { useStores } from "../stores/context";

const paginationStore = new PaginationStore();
const filterStore = new FilterStore();

const VehiclePage = observer(() => {
  const { id: vehicleId } = useParams();
  const { itemsLength, limit, offset, currentPage } = paginationStore;
  const { query, filters } = filterStore;

  const createFilterString = () => {
    if (!filters || filters.length === 0) {
      return "";
    }

    const baseString = "&";
    const string = baseString + filters.join(baseString);

    return string;
  };

  let navigate = useNavigate();

  const { authStore } = useStores();

  const [isLoading, setIsLoading] = useState(true);
  const [vehicleList, setVehicleList] = useState([]);

  useEffect(() => {
    if (config.AUTH_disabled) {
      setVehicleList(mockVehicleData);
      paginationStore.setItemsLength(200);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      get(
        `/v1/devices/?offset=${offset}&limit=${limit}&s=${query}${createFilterString()}`
      ).then((data) => {
        setVehicleList(data.results);
        paginationStore.setItemsLength(data.totalCount);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [offset, limit, currentPage, query, filters]);

  const rowClicked = (row) => {
    if (
      authStore.hasRole("User") ||
      authStore.hasRole("Admin") ||
      config.AUTH_disabled
    ) {
      navigate(`/vehicles/${row.id}`);
    } else
      Notification.error(
        "Insufficient permissions to access Vehicle Details",
        "No Permission",
        10000
      );
  };
  useEffect(() => {
    // When unmounting the page:
    return () => {
      paginationStore.resetToDefaults();
      filterStore.resetToDefaults();
    };
  }, []);
  return (
    <ApplicationLayout className="MyServiceLayout bg-lighter">
      <ApplicationLayout.Header>
        <NavBar />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar>
        <RMSideBar />
      </ApplicationLayout.Sidebar>
      {vehicleId && (
        <ApplicationLayout.Sidebar className="right">
          <VehicleDetailSideBar vehicleId={vehicleId} />
        </ApplicationLayout.Sidebar>
      )}
      <ApplicationLayout.Body>
        <NotificationsContainer />
        <VehicleTable
          isLoading={isLoading}
          vehicleList={vehicleList}
          selectedRow={vehicleId}
          rowClicked={rowClicked}
          paginationStore={paginationStore}
          itemsLength={itemsLength}
          filterStore={filterStore}
          vehicleTableSettings={vehicleTableSettings}
        />
        <Pagination size={itemsLength} paginationStore={paginationStore} />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
});

export default VehiclePage;
