import ClipboardIcon from "../../../ClipboardIcon/ClipboardIcon";

const TableCellVINRenderer = (props) => {
  const { dataField, columnItem } = props;

  return (
    <td data-field={dataField}>
      <div className={"display-flex flex-row align-items-center"}>
        {columnItem}
        <ClipboardIcon textToCopy={columnItem} />
      </div>
    </td>
  );
};

export default TableCellVINRenderer;
