const messageList = [
  {
    id: "123",
    severity: "Low",
    type: "Info",
    status: "Created",
    timeStampStatus: "5 Minutes ago",
    timeStampCreated: new Date(),
  },
  {
    id: "124",
    severity: "Low",
    type: "Info",
    status: "Pending",
    timeStampStatus: "5 Minutes ago",
    timeStampCreated: new Date(),
  },
  {
    id: "125",
    severity: "Low",
    type: "Info",
    status: "Pending",
    timeStampStatus: "5 Minutes ago",
    timeStampCreated: new Date(),
  },
  {
    id: "1234",
    severity: "Low",
    type: "Info",
    status: "Pending",
    timeStampStatus: "5 Minutes ago",
    timeStampCreated: new Date(),
  },
  {
    id: "12345",
    severity: "Low",
    type: "Info",
    status: "Pending",
    timeStampStatus: "5 Minutes ago",
    timeStampCreated: new Date(),
  },
  {
    id: "1231",
    severity: "Low",
    type: "Info",
    status: "Pending",
    timeStampStatus: "5 Minutes ago",
    timeStampCreated: new Date(),
  },
  {
    id: "12334",
    severity: "Low",
    type: "Info",
    status: "Pending",
    timeStampStatus: "5 Minutes ago",
    timeStampCreated: new Date(),
  },
  {
    id: "123134",
    severity: "Low",
    type: "Info",
    status: "Pending",
    timeStampStatus: "5 Minutes ago",
    timeStampCreated: new Date(),
  },
  {
    id: "1232342",
    severity: "Low",
    type: "Info",
    status: "Pending",
    timeStampStatus: "5 Minutes ago",
    timeStampCreated: new Date(),
  },
];

export default messageList;
