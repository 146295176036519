import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import Auth from "../components/Auth/Auth";
import { NotificationsContainer } from "@rio-cloud/rio-uikit";

export default function AuthPage() {
  return (
    <ApplicationLayout>
      <ApplicationLayout.Body classname="auth">
        <NotificationsContainer />
        <Auth />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
}
