const TableCellStatusRenderer = (props) => {
  const { columnItem, dataField } = props;

  const labelClasses = {
    Created: "rioglyph rioglyph-envelope",
    Approved: "rioglyph rioglyph-thumbs-up",
    Declined: "rioglyph rioglyph-thumbs-down",
    In_transfer: "rioglyph rioglyph-delivery-on-track",
    Sent: "rioglyph rioglyph-send",
    Failed: "rioglyph rioglyph-warning-sign",
  };

  return columnItem === "ACTIVE" || columnItem === "DELETED" ? (
    <td data-field={dataField}>
      <span>{columnItem}</span>
    </td>
  ) : (
    <td data-field={dataField}>
      <div
        className={
          "max-width-150 border margin-auto border-color-dark text-medium border-width-2 rounded-large padding-top-5 padding-bottom-5 padding-right-10  padding-left-10 display-flex align-items-center"
        }
      >
        <i className={` ${labelClasses[columnItem]}`}></i>
        <div className={`margin-auto`}>
          {columnItem === "In_transfer" ? "In Transfer" : columnItem}
        </div>
      </div>
    </td>
  );
};

export default TableCellStatusRenderer;
