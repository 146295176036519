import Switch from "@rio-cloud/rio-uikit/Switch";

const WhitelistButton = (props) => {
  const { whitelistButtonActive, handleClickWhiteListButton } = props;
  return (
    <div className="table-toolbar-column border border-color-black border-width-1 padding-top-6 padding-bottom-6 padding-left-10 padding-right-10">
      <div className="display-flex">
        <Switch
          checked={whitelistButtonActive}
          onChange={handleClickWhiteListButton}
          labelPosition={"left"}
        >
          <span className="text-color-black padding-right-5">
            Show Testracks
          </span>
        </Switch>
      </div>
    </div>
  );
};

export default WhitelistButton;
