import TableCellShortener from "./TableCellShortener";
import { useRef } from "react";
import useResizableElementWidth from "./hooks/useResizableElementWidth";
import { breakpointsDevices } from "./constants/constants";
import ClipboardIcon from "../../../ClipboardIcon/ClipboardIcon";

export default function TableCellDeviceRenderer(props) {
  const { dataField, id } = props;
  const elRef = useRef();

  const width = useResizableElementWidth(elRef, props);

  return (
    <td data-field={dataField}>
      <div ref={elRef} className={"display-flex flex-row align-items-center"}>
        <span
          className={
            "border-style-solid border-width-1 rounded padding-3 margin-right-5"
          }
        >
          {id && id[0]?.type}
        </span>
        <TableCellShortener
          cellData={id && id[0]?.serialNumber}
          parentCellSize={width}
          breakpoints={breakpointsDevices}
        />
        <ClipboardIcon textToCopy={id[0]?.serialNumber} />
      </div>
    </td>
  );
}
