import TableCellShortener from "./TableCellShortener";
import { useRef } from "react";
import useResizableElementWidth from "./hooks/useResizableElementWidth";
import { breakpointsTypes } from "./constants/constants";

const TableCellMessageTypeRenderer = (props) => {
  const { id, dataField } = props;
  const elRef = useRef();

  const width = useResizableElementWidth(elRef, props);

  return (
    <td data-field={dataField}>
      <div ref={elRef}>
        <TableCellShortener
          cellData={id}
          parentCellSize={width}
          breakpoints={breakpointsTypes}
        />
      </div>
    </td>
  );
};

export default TableCellMessageTypeRenderer;
