import SteppedProgressBar from "@rio-cloud/rio-uikit/SteppedProgressBar";

const ProgressBarLabels = (props) => {
  const { status } = props;

  const labels = {
    created: {
      icon: <i className="rioglyph rioglyph-envelope" />,
      label: "Created",
    },
    approved: {
      icon: <i className="rioglyph rioglyph-thumbs-up" />,
      label: "Approved",
    },
    declined: {
      icon: <i className="rioglyph rioglyph-thumbs-down" />,
      label: "Declined",
    },
    sent: {
      icon: <i className="rioglyph rioglyph rioglyph-send" />,
      label: "Sent",
    },
    inTransfer: {
      icon: <i className="rioglyph rioglyph-delivery-on-track" />,
      label: "In Transfer",
    },
    approvedOrDeclined: {
      icon: (
        <>
          <i className="rioglyph rioglyph rioglyph-thumbs-up" />
          <i className="rioglyph rioglyph  rioglyph-thumbs-down" />
        </>
      ),
      label: "Approved/Declined",
    },
    failed: {
      icon: <i className="rioglyph rioglyph-warning-sign" />,
      label: "Failed",
    },
  };

  const {
    created,
    approved,
    declined,
    sent,
    approvedOrDeclined,
    inTransfer,
    failed,
  } = labels;

  switch (status) {
    case "Created":
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={0}
          disableFollowingPages={0}
          className={"pointer-events-none"}
          labels={[created, approvedOrDeclined, inTransfer, sent]}
        />
      );
    case "Declined":
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={1}
          className={"margin-right-50 margin-left-50 pointer-events-none"}
          labels={[created, declined]}
        />
      );
    case "Approved":
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={1}
          disableFollowingPages={1}
          className={"pointer-events-none"}
          labels={[created, approved, inTransfer, sent]}
        />
      );
    case "Sent":
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={3}
          disableFollowingPages={3}
          className={"pointer-events-none"}
          labels={[created, approved, inTransfer, sent]}
        />
      );
    case "In_transfer":
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={2}
          disableFollowingPages={2}
          className={"pointer-events-none"}
          labels={[created, approved, inTransfer, sent]}
        />
      );
    case "Failed":
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={3}
          disableFollowingPages={3}
          className={"pointer-events-none"}
          labels={[created, approved, inTransfer, failed]}
        />
      );
    default:
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={0}
          disableFollowingPages={0}
          className={"pointer-events-none"}
          labels={[created, approved, declined, inTransfer, sent]}
        />
      );
  }
};

export default ProgressBarLabels;
