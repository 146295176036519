import React, { useEffect, useState } from "react";
import { useStores } from "../../stores/context";
import { Navigate } from "react-router";
import FullPageLoader from "../Common/Loading/FullPageLoader";

const AuthHandle = () => {
  const [loading, setLoading] = useState(true);
  const { authStore } = useStores();
  useEffect(() => {
    setTimeout(
      () =>
        authStore.completeLogin().then(() => {
          if (authStore.isLoggedIn) {
            setLoading(false);
          }
        }),
      1000
    );
  }, [authStore]);
  return loading ? (
    <FullPageLoader />
  ) : (
    <Navigate to={authStore.getRootURIForUser()} />
  );
};

export default AuthHandle;
