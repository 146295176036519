import ContentLoader from "@rio-cloud/rio-uikit/ContentLoader";
import ClipboardIcon from "../../Common/ClipboardIcon/ClipboardIcon";

const VehicleDetailsDevices = (props) => {
  const { isLoading, vehicleDetails } = props;

  return (
    <>
      <h5>
        <span className="rioglyph rioglyph rioglyph-antenna text-size-20 margin-right-10"></span>
        Device
      </h5>
      <div
        data-testid="vehicle-details-general-section"
        className="breakline margin-bottom-20"
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="margin-bottom-10">
            <label className="display-block">Device Type</label>

            {isLoading || !vehicleDetails?.devices ? (
              <ContentLoader />
            ) : (
              <span
                className={
                  vehicleDetails?.devices
                    ? "border-style-solid border-width-1 rounded padding-3 margin-right-5"
                    : ""
                }
              >
                {vehicleDetails?.devices && vehicleDetails?.devices[0]?.type}
              </span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="margin-bottom-10">
            <label className="display-block">Device UID</label>

            {isLoading || !vehicleDetails?.devices ? (
              <ContentLoader />
            ) : (
              <span className="text-primary">
                {vehicleDetails?.devices[0]?.rioDeviceId ? (
                  <>
                    <span>{vehicleDetails?.devices[0]?.rioDeviceId}</span>
                    <ClipboardIcon
                      textToCopy={vehicleDetails?.devices[0]?.rioDeviceId}
                    />
                  </>
                ) : (
                  "No Device UID found"
                )}
              </span>
            )}
          </div>
        </div>
        <div className="margin-bottom-10">
          <label className="display-block">Device S/N</label>

          {isLoading || !vehicleDetails?.devices ? (
            <ContentLoader />
          ) : (
            <span className="text-primary">
              {vehicleDetails?.devices && (
                <>
                  <span>{vehicleDetails?.devices[0]?.serialNumber}</span>
                  <ClipboardIcon
                    textToCopy={vehicleDetails?.devices[0]?.serialNumber}
                  />
                </>
              )}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default VehicleDetailsDevices;
