import Sidebar from "@rio-cloud/rio-uikit/Sidebar";
import ListMenu from "@rio-cloud/rio-uikit/lib/es/ListMenu";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const menuItems = [
  {
    group: "Vehicles",
    navItems: [
      {
        key: "1",
        item: (
          <NavLink data-testid="vehicles-overview" to="/vehicles">
            Overview
          </NavLink>
        ),
      },
    ],
  },
  {
    group: "Messages",
    navItems: [
      {
        key: "1",
        item: (
          <NavLink data-testid="messages-overview" to="/messages">
            Overview
          </NavLink>
        ),
      },
    ],
  },
  {
    group: "Statistics",
    navItems: [
      {
        key: "1",
        item: (
          <NavLink data-testid="statistics-dashboard" to="/stats/dashboard">
            Dashboard
          </NavLink>
        ),
      },
      {
        key: "2",
        item: (
          <NavLink data-testid="statistics-timeline" to="/stats/timeline">
            Timeline
          </NavLink>
        ),
      },
    ],
  },
  {
    group: "Support",
    navItems: [
      {
        key: "1",
        item: (
          <NavLink data-testid="support-dashboard" to="/support">
            Contact
          </NavLink>
        ),
      },
    ],
  },
];

export default function RMSideBar(props) {
  const [activeItem, setActiveItem] = useState("0");
  const [displayedVavItems, setDisplayedNavItems] = useState(
    () => () => menuItems
  );

  const { showNavElements } = props;

  const onItemClick = (event) => {
    const key = event.target.getAttribute("data-key");
    setActiveItem(key);
  };

  useEffect(() => {
    if (showNavElements && showNavElements.length > 0) {
      const filteredMenuItems = menuItems.filter((navElement) => {
        return (
          navElement.group ===
          showNavElements.find((el) => el === navElement.group)
        );
      });
      setDisplayedNavItems(() => () => filteredMenuItems);
    }
  }, [showNavElements]);

  return (
    <Sidebar title="Menu" className={"RMSideBar"} width={180}>
      <ListMenu menuItems={displayedVavItems(activeItem, onItemClick)} />
    </Sidebar>
  );
}
