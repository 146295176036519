import TableCellShortener from "./TableCellShortener";
import { useRef } from "react";
import useResizableElementWidth from "./hooks/useResizableElementWidth";
import { breakpointsTitles } from "./constants/constants";

const TableCellTitleRenderer = (props) => {
  const { title, dataField } = props;
  const elRef = useRef();

  const width = useResizableElementWidth(elRef, props);

  return (
    <td data-field={dataField}>
      <div ref={elRef}>
        <TableCellShortener
          cellData={title}
          parentCellSize={width}
          breakpoints={breakpointsTitles}
        />
      </div>
    </td>
  );
};

export default TableCellTitleRenderer;
