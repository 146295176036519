import { useStores } from '../../stores/context';
import Logo from '../../assets/img/man-logo.png';


export default function Auth() {
    const { authStore } = useStores();
    let year = new Date().getFullYear();

    return (
        <div className="text-center">
            <div className="login">
                <div className="logo">
                    <img alt="MAN Logo" src={Logo} />
                </div>
                <div className="login-box panel panel-default text-center">
                    <div className="panel-body">
                        <p className="lead margin-25">Sign in to MAN RM Admin</p>
                        <button className="btn btn-lg btn-primary margin-bottom-20" onClick={authStore.login}>
                            Sign in
                        </button>
                    </div>
                </div>
                <p className="text-color-gray">
                    &copy; {year} <a href="https://man.eu">man.eu</a> – All rights reserved
                </p>
            </div>
        </div>
    )
}