const SeverityLabelRenderer = (severityText) => {
  const labelClasses = {
    Low: "border-color-success text-color-success",
    LOW: "border-color-success text-color-success",
    Medium: "border-color-warning text-color-warning",
    High: "border-color-danger text-color-danger",
  };
  return labelClasses[severityText];
};

export default SeverityLabelRenderer;
