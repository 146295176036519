import { useState } from "react";
import { Dialog, SteppedProgressBar } from "@rio-cloud/rio-uikit";
import TargetSelection from "./Steps/TargetSelection";
import MessageForm from "./Steps/MessageForm";
import ConfirmMessage from "./Steps/ConfirmMessage";
import Notification from "@rio-cloud/rio-uikit/Notification";
import Footer from "./Footer";
import { post } from "../../../api/apiService";
import { useStores } from "../../../stores/context";
import { useNavigate } from "react-router-dom";

export default function CreateMessage() {
  const [showDialog, setShowDialog] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedType, setSelectedType] = useState("Select Type");
  const [selectedSeverity, setSelectedSeverity] = useState("Select Severity");
  const [titleInputValue, setTitleInputValue] = useState("");
  const [KBAInputValue, setKBAInputValue] = useState("");
  const [contentInputValue, setContentInputValue] = useState("");
  const [showToolTipTitle, setShowToolTipTitle] = useState(false);
  const [showToolTipContent, setShowToolTipContent] = useState(false);
  const [showToolTipType, setShowToolTipType] = useState(false);
  const [showToolTipSeverity, setShowToolTipSeverity] = useState(false);
  const [showKBAToolTip, setShowKBAToolTip] = useState(false);
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);

  const { authStore } = useStores();

  let navigate = useNavigate();

  const KBAregex = /^\d{6}$/;

  const createMessage = () => {
    const message = {
      vehicleId: selectedRow,
      title: titleInputValue,
      content: contentInputValue,
      severity: selectedSeverity,
      type: selectedType,
      kbaNumber: KBAInputValue,
      status: "Created",
      timeStampStatus: new Date(),
      timeStampCreated: new Date(),
      createdBy: authStore.user?.profile?.email,
    };
    setShowLoadingAnimation(true);
    post("/v1/messages", message)
      .then((response) => {
        setShowLoadingAnimation(false);
        return response;
      })
      .then((response) => {
        navigate(`/messages/${response.messageId}`, {
          state: { messageCreated: true },
        });
        setShowDialog(false);
        resetFormToDefaults();
      })
      .catch((error) => {
        console.error("Error:", error);
        Notification.error(
          `Could not create Message. ${error}`,
          "Error",
          100000
        );
      });
  };

  const resetFormToDefaults = () => [
    setStep(0),
    setSelectedVehicle(null),
    setSelectedRow(null),
    setSelectedType("Select Type"),
    setSelectedSeverity("Select Severity"),
    setTitleInputValue(""),
    setKBAInputValue(""),
    setContentInputValue(""),
  ];

  const handleTitleInputChange = (e) => {
    setTitleInputValue(e.target.value);
    setShowToolTipTitle(false);
  };

  const handleTypeInputChange = (option) => {
    setSelectedType(option);
    setShowToolTipType(false);
  };

  const handleSeverityInputChange = (option) => {
    setSelectedSeverity(option);
    setShowToolTipSeverity(false);
  };

  const handleContentInputChange = (e) => {
    setContentInputValue(e.target.value);
    setShowToolTipContent(false);
  };

  const handleKBAInputChange = (e) => {
    if (KBAregex.test(e.target.value)) {
      setShowKBAToolTip(false);
    }
    if (!KBAregex.test(e.target.value)) {
      setShowKBAToolTip(true);
    }
    setKBAInputValue(e.target.value);
  };

  const onShow = () => {
    setShowDialog(true);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const onNextStep = () => {
    if (!selectedVehicle) {
      Notification.warning("Please select a vehicle.");
      return;
    }
    if (
      step === 1 &&
      (!titleInputValue ||
        !contentInputValue ||
        selectedType === "Select Type" ||
        selectedSeverity === "Select Severity" ||
        (!KBAregex.test(KBAInputValue) && selectedType === "Recall"))
    ) {
      if (!titleInputValue) {
        setShowToolTipTitle(true);
      }
      if (!contentInputValue) {
        setShowToolTipContent(true);
      }
      if (selectedType === "Select Type") {
        setShowToolTipType(true);
      }
      if (selectedSeverity === "Select Severity") {
        setShowToolTipSeverity(true);
      }
      if (!KBAregex.test(KBAInputValue) && selectedType === "Recall") {
        setShowKBAToolTip(true);
      }
      Notification.warning("Please fill out all fields.");
      return;
    }
    if (step === 1 && !contentInputValue) {
      setShowToolTipContent(true);
      return;
    }
    let update_step = step + 1;
    if (update_step > steps.length) {
      update_step = steps.length;
    }
    setStep(update_step);
  };

  const onPreviousStep = () => {
    let update_step = step - 1;
    if (update_step < 0) {
      update_step = 0;
    }
    setStep(update_step);
  };

  const handleSelectedChanged = (selectedKey) => setStep(selectedKey);

  const stepLabels = [
    {
      icon: "1",
      label: "Select Target",
    },
    {
      icon: "2",
      label: "Message",
    },
    {
      icon: <i className="rioglyph rioglyph-ok" />,
      label: "Overview",
    },
  ];

  const steps = [
    <TargetSelection
      setSelectedVehicle={setSelectedVehicle}
      setSelectedRow={setSelectedRow}
      vehicleId={selectedRow}
    />,
    <MessageForm
      selectedType={selectedType}
      selectedVehicle={selectedVehicle}
      selectedSeverity={selectedSeverity}
      titleInputValue={titleInputValue}
      handleTitleInputChange={handleTitleInputChange}
      contentInputValue={contentInputValue}
      setContentInputValue={setContentInputValue}
      handleContentInputChange={handleContentInputChange}
      showToolTipTitle={showToolTipTitle}
      showToolTipContent={showToolTipContent}
      showToolTipType={showToolTipType}
      handleTypeInputChange={handleTypeInputChange}
      showToolTipSeverity={showToolTipSeverity}
      handleSeverityInputChange={handleSeverityInputChange}
      showKBAToolTip={showKBAToolTip}
      handleKBAInputChange={handleKBAInputChange}
      KBAInputValue={KBAInputValue}
    />,
    <ConfirmMessage
      selectedType={selectedType}
      selectedVehicle={selectedVehicle}
      selectedSeverity={selectedSeverity}
      titleInputValue={titleInputValue}
      contentInputValue={contentInputValue}
      KBAInputValue={KBAInputValue}
      showLoadingAnimation={showLoadingAnimation}
    />,
  ];

  const body = (
    <div className={"messageDialog"}>
      <div>
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={step}
          labels={stepLabels}
          onSelectedChanged={handleSelectedChanged}
          id={"create-message-progess-bar"}
          className={"margin-bottom-70"}
        />
      </div>
      <div>{steps[step]}</div>
    </div>
  );
  return (
    <>
      {showDialog && (
        <Dialog
          show={showDialog}
          title={"Create Message"}
          footer={
            <Footer
              step={step}
              onNextStep={onNextStep}
              steps={steps}
              onPreviousStep={onPreviousStep}
              createMessage={createMessage}
            />
          }
          body={body}
          bsSize={"xl"}
          onHide={onHide}
          className="exampleDialog"
          showCloseButton
        />
      )}

      <button
        type="button"
        className="btn btn-primary"
        onClick={() => onShow()}
      >
        Create Message
      </button>
    </>
  );
}
