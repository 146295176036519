import { Checkbox } from "@rio-cloud/rio-uikit";
import { useState } from "react";
import { observer } from "mobx-react-lite";

const FilterBody = observer((props) => {
  const { filterStore } = props;
  const [boxChecked, setBoxChecked] = useState(
    filterStore.filters.includes("status=active")
  );

  const toggleFilter = () => {
    const filter = "status=active";
    if (filterStore.filters.includes("status=active")) {
      filterStore.removeFilter(filter);
    } else {
      filterStore.addFilter(filter);
    }
    setBoxChecked(!boxChecked);
  };
  return (
    <>
      <h5 className="margin-bottom-15 margin-top-0">Filter vehicles</h5>
      <Checkbox checked={boxChecked} onClick={() => toggleFilter()}>
        Hide deleted vehicles
      </Checkbox>
    </>
  );
});

export default FilterBody;
