import Table from "../../Common/Table/Table";

import { observer } from "mobx-react-lite";

export default observer((props) => {
  const {
    selectedRow,
    rowClicked,
    paginationStore,
    filterStore,
    vehicleList,
    isLoading,
    vehicleTableSettings,
  } = props;

  const {
    defaultColumnOrder,
    columnLabels,
    disabledColumns,
    hiddenColums,
    columnDetails,
    toolbarOptions,
  } = vehicleTableSettings;

  return (
    <>
      <Table
        defaultColumnOrder={defaultColumnOrder}
        columnLabels={columnLabels}
        data={vehicleList}
        toolbarOptions={toolbarOptions}
        defaultColumnDetails={columnDetails}
        disabledColumns={disabledColumns}
        isLoading={isLoading}
        selectedRow={selectedRow}
        rowKey="id"
        rowClicked={rowClicked}
        filterStore={filterStore}
        paginationStore={paginationStore}
        defaultHiddenColums={hiddenColums}
      />
    </>
  );
});
